import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
// import { PDFViewer } from 'react-view-pdf';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from 'reactstrap';

// import { handleError } from '@services/ErrorHandler';
import { downloadFile, downloadTempFile } from '@services/FileService';
import LocaleService from '@services/LocaleService';
import { getFileExtension } from '@shared/helpers';
import { DownloadIcon } from '@shared/icons';

import './CustomDocumentViewerStyle.scss';

const i18n = LocaleService.getTranslations('general');
interface CustomDocumentViewerFunctions {
  show: (data: any) => void;
  hide: () => void;
}

export const CustomDocumentViewer: CustomDocumentViewerFunctions = {
  show: () => {
    // Your implementation here
  },
  hide: () => {
    // Your implementation here
  },
};

export const useCustomDocumentViewer = () => CustomDocumentViewer;

const CustomDocumentViewerComponent: React.FC = () => {
  const [ showModal, setShowModal ] = useState(false);
  const [ docs, setDocs ] = useState<any>([]);
  const [ showDownload, setShowDownload ] = useState(false);
  const [ downloading, setDownloading ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ type, setType ] = useState('');
  // const [ key, setKey ] = useState(Math.random());
  const timeoutInterval = useRef<NodeJS.Timer>();

  const confirmClose = () => {
    setShowModal(false);
    setDocs([
      {
        uri: '',
        fileType: 'docx',
        fileName: '',
        tempFilename: '',
      },
    ]);
    clearInterval(timeoutInterval.current);
  };

  const downloadAttachment = async () => {
    setDownloading(true);
    if (docs && docs?.length > 0) {
      try {
        const filename = docs[0]?.tempFilename ?? docs[0].s3Filename;

        if (docs[0]?.tempFilename) {
          await downloadTempFile(filename, type);
          setDownloading(false);
          return;
        }

        await downloadFile(filename);
        setDownloading(false);
      } catch (error) {
        // handleError(error);
        setDownloading(false);
      }
    }
  };

  useEffect(() => {
    CustomDocumentViewer.show = (data: any) => {
      const newDocs = [
        {
          uri: data.url,
          fileType: data?.customFileType || getFileExtension(data.filename),
          fileName: data.filename,
          s3Filename: data.s3Filename,
          tempFilename: data.temp_filename,
        },
      ];
      setType(data?.type);
      setShowDownload(data.showDownload);
      setDocs(newDocs);
      setShowModal(true);
      setLoading(true);
      // setKey(Math.random());

      // // reload iframe if not loaded in 5 secs
      // timeoutInterval.current = setInterval(() => {
      //   setKey(Math.random());
      // }, 7000);
    };
    CustomDocumentViewer.hide = () => confirmClose();
  }, []);

  if (!showModal) return null;

  return (
    <Modal
      isOpen={showModal}
      toggle={confirmClose}
      centered
      fullscreen
      className="document-modal"
    >
      <ModalHeader tag="div" toggle={confirmClose} className="w-100">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="mb-0">
            {docs[0].fileName}
          </h5>
          <div className="text-right me-3">
            {
              showDownload
                ? (
                  <a
                    href="#"
                    aria-label="download-preview-template"
                    className={classNames('download-button-style', {
                      'disabled-download-button': downloading,
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      downloadAttachment();
                    }}
                  >
                    <DownloadIcon /> {downloading
                      ? i18n.button.download.loading
                      : i18n.button.download.name}
                  </a>
                ) : null
            }
          </div>
        </div>
      </ModalHeader>
      <ModalBody className={classNames('b2', {
        'pdf-viewer-active': docs[0]?.fileType === 'pdf' && true,
      })}
      >
        {loading && (
          <div className="doc-loader">
            <Spinner />
          </div>
        )}
        <iframe
          // key={key}
          // src={`https://docs.google.com/gview?url=${encodeURIComponent(docs[0].uri)}&embedded=true`}
          // src={`https://docs.google.com/viewer?url=${encodeURIComponent(docs[0].uri)}&embedded=true`}
          // src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(docs[0].uri)}`}
          src={docs[0].fileType === 'pdf'
            ? `https://docs.google.com/viewer?url=${encodeURIComponent(docs[0].uri)}&embedded=true`
            : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(docs[0].uri)}`}
          title="Document"
          frameBorder={0}
          width="100%"
          height="99%"
          // sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          onLoad={() => {
            setLoading(false);
            // clearInterval(timeoutInterval.current);
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default CustomDocumentViewerComponent;
