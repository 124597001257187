import * as React from 'react';
import { SVGProps } from 'react';

const UploadImageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <rect width={20} height={20} x={0.25} fill="#FEFEFE" rx={3} />
      <path
        fill="#FF3093"
        fillRule="evenodd"
        d="M2.4 0A2.4 2.4 0 0 0 0 2.4v15.2A2.4 2.4 0 0 0 2.4 20h15.2a2.4 2.4 0 0 0 2.4-2.4V2.4A2.4 2.4 0 0 0 17.6 0H2.4Zm4.943 12.051c.389.52.78 1.04 1.095 1.04.594 0 1.25-.976 1.922-1.974.913-1.36 1.854-2.76 2.7-1.786.532.613 1.08 1.954 1.563 3.135.168.411.328.804.477 1.139v.475c0 .564-.456 1.02-1.02 1.02H5.92a1.02 1.02 0 0 1-1.02-1.02v-.336c.193-.433.39-.92.568-1.362.176-.435.334-.825.452-1.075.335-.707.878.017 1.423.744ZM7.96 6.43a1.53 1.53 0 1 1-3.059.001A1.53 1.53 0 0 1 7.96 6.43Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default UploadImageIcon;
