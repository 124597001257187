import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route, Routes, Navigate, createBrowserRouter,
} from 'react-router-dom';
import { Container } from 'reactstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import AuthRouter from '@components/auth/AuthRouter';
import ClientRouter from '@components/client/ClientRouter';
import ErrorRouter from '@components/error/ErrorRouter';
import { getExpeditedServiceCostRequest, getStatesRequest } from '@reducers/app/AppAction';
import { getUserDetailsRequest } from '@reducers/auth/AuthAction';
import Reducers from '@reducers/main';
import { handleError } from '@services/ErrorHandler';
import { LoadingBlock } from '@shared/utils';

type MyDispatch = ThunkDispatch<typeof Reducers, unknown, AnyAction>;

const AppRouter = () => {
  const dispatch = useDispatch<MyDispatch>();
  const [ errorCode, setErrorCode ] = useState(null);
  const { isLoggedIn } = useSelector((state:any) => ({
    // locale: state.Settings.locale || '',
    isLoggedIn: !!state.Auth.accessToken,
  }));
  const loading = useSelector((state:any) => state.Loading.isLoading);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const fetchUserDetails = async () => {
      setErrorCode(null);
      try {
        await dispatch(getUserDetailsRequest());
      } catch (error:any) {
        const { response } = error;
        setErrorCode(response.status);
      }
    };

    fetchUserDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isLoggedIn ]);

  const getServices = async () => {
    try {
      await dispatch(getExpeditedServiceCostRequest({ keyword: 'expedite' }));
      await dispatch(getStatesRequest());
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    getServices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  if (loading) return <LoadingBlock enabled />;

  return (
    <Container className="h-100">
      <Routes>
        <Route path="/errors" element={<ErrorRouter />} />
        {errorCode && <Route path="*" element={<Navigate to={`/errors${errorCode}`} />} />}
        <Route
          path="/auth/*"
          element={!isLoggedIn ? <AuthRouter /> : <Navigate to="/" />}
        />
        <Route
          path="/*"
          element={
            isLoggedIn ? (
              <ClientRouter />
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route path="/auth/*" element={<AuthRouter />} />
      </Routes>
    </Container>
  );
};

export const router = createBrowserRouter([
  { path: '*', Component: AppRouter },
]);

export default AppRouter;
