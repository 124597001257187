/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const sendMessageAction = createAction('APP_TICKET_SEND_MESSAGE');
export const displayMessageAction = createAction('APP_TICKET_DISPLAY_MESSAGE');
export const updateMessageAction = createAction('APP_TICKET_UPDATE_MESSAGE');
export const deleteMessageAction = createAction('APP_TICKET_DELETE_MESSAGE');
export const getTicketMessagesAction = createAction('APP_TICKET_GET_MESSAGES');
export const markTicketConversationAsReadAction = createAction('APP_TICKET_MARK_CONVERSATION_AS_READ');
