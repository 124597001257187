import {
  getRequest, postRequest, deleteRequest, putRequest,
} from '@services/RequestService';

export const generateGetAction = (action, url, options, params) => (dispatch) => {
  const request = getRequest(url, options);
  return request.$promise
    .then(response => {
      dispatch({ type: action, payload: response.data, params });
      return response;
    });
};

export const generatePostAction = (action, url, params, options) => (dispatch) => {
  const request = postRequest(url, params, options);
  return request.$promise
    .then(response => {
      dispatch({ type: action, payload: response.data, params });
      return response;
    });
};

export const generateDeleteAction = (action, url, params) => (dispatch) => {
  const request = deleteRequest(url, params);
  return request.$promise
    .then(response => {
      dispatch({ type: action, payload: response.data, params });
      return response;
    });
};

export const generatePutAction = (action, url, params, options) => (dispatch) => {
  const request = putRequest(url, params, options);
  return request.$promise
    .then(response => {
      dispatch({ type: action, payload: response.data, params });
      return response;
    });
};

// eslint-disable-next-line max-len
export const generateAction = (action, payload) => (dispatch) => dispatch({ type: action, payload });
