import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';

import TopNav from './components/topnav/TopNav';
// import useLayout from '@shared/hooks/useLayout';
// import Nav from '@components/admin/layout/nav/Nav';

const Layout: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.click();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
    }, [pathname]);

  return (
    <Container className="h-100">
      <TopNav />
      <main className="h-100">
        <Outlet />
      </main>
    </Container>
  );
};

export default React.memo(Layout);
