/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames';
import React, {
  useEffect, useState,
} from 'react';
import Webcam from 'react-webcam';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

import LocaleService from '@services/LocaleService';
import {
  CameraIcon, RefreshIcon,
} from '@shared/icons';

import './WebcamCapture.scss';

const WebcamCapture = ({ onSuccess, show, toggle }: {
  show: boolean;
  toggle: () => void;
  onSuccess: (files: File[]) => void;
}) => {
  const i18n = LocaleService.getTranslations('general');
  const webcamRef = React.useRef<Webcam>(null);
  const [ photo, setPhoto ] = useState('');

  const capturePhoto = () => {
    const p: any = webcamRef.current?.getScreenshot();
    setPhoto(p);
  };

  const resetCapture = (resetPhotoVideo = true) => {
    if (resetPhotoVideo) {
      setPhoto('');
    }
  };

  const handleUpload = async () => {
    const blob = await fetch(photo).then((res) => res.blob());
    onSuccess([ new File([ blob as any ], 'photo.png', { type: 'image/png' }) ]);
    toggle();
  };

  useEffect(() => {
    if (show) {
      navigator.permissions.query({ name: 'camera' as any });
    } else {
      resetCapture();
    }
  }, [ show ]);

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      centered
      size="lg"
      className="webcam-capture-modal"
    >
      <ModalHeader toggle={toggle} />
      <ModalBody className="p-0 d-flex align-items-center justify-content-center">
        {(show && !photo) && (
          <Webcam
            ref={webcamRef}
            className="webcam"
            width={800}
            style={{
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          />
        )}
        {photo && (
          <img
            src={photo}
            style={{
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
            alt="Captured"
          />
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <div className="btn-control text-center" />
        <div className="btn-control text-center">
          {photo ? (
            <Button
              color="danger"
              className="btn-capture btn-retry"
              onClick={() => resetCapture()}
            >
              <RefreshIcon width="30" height="30" />
            </Button>
          ) : (
            <Button
              color="primary"
              className="btn-capture"
              onClick={capturePhoto}
            >
              <CameraIcon width="30" height="30" />
            </Button>
          )}
        </div>

        <div className="btn-control text-end">
          <Button
            color="primary"
            size="sm"
            className={classNames({
              'btn-upload': true,
              invisible: !photo,
            })}
            onClick={handleUpload}
          >
            Upload
            {i18n.upload.upload}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default WebcamCapture;
