import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import LocaleService from '@services/LocaleService';
import { CheckIcon, RefreshIcon, SentIcon } from '@shared/icons';

import './ChatMessage.scss';

const ChatMessage = ({
  message, sent = false, sending, failed, date, onRetry,
}: {
  message: string;
  date: string;
  sent?: boolean;
  sending?: boolean;
  failed?: boolean;
  onRetry: () => void;
}) => {
  const i18n = LocaleService.getTranslations('chat');

  return sent ? (
    <div
      onClick={failed ? onRetry : undefined}
      className={classNames('chat-message sent', {
        failed,
      })}
    >
      <div className="wrap">
        {message}
      </div>
      {failed ? (
        <div className="d-flex align-items-center retry">
          {i18n.button.retry}
          <RefreshIcon width={10} className="ms-1" />
        </div>
      ) : (
        <div className="d-flex align-items-center gap-1">
          <div className="timestamp">
            {moment.utc(date).local().format('hh:mm A')}
          </div>
          {sending ? (
            <CheckIcon className="icon" />
          ) : (
            <SentIcon className="icon" />
          )}
        </div>
      )}
    </div>
  ) : (
    <div className="chat-message received">
      <div className="wrap">
        {message}
      </div>
      <div className="timestamp">
        {moment.utc(date).local().format('hh:mm A')}
      </div>
    </div>
  );
};

export default ChatMessage;
