import PropTypes from 'prop-types';
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import './LoadingStyle.scss';
import { Row, Col, Container } from 'reactstrap';

const LoadingRoot = forwardRef(({ show, cover }, ref) => {
  const [ data, setData ] = useState({
    loading: show,
    full: cover,
  });

  useImperativeHandle(
    ref,
    () => ({
      show: (isCover) => setData({ loading: true, full: isCover }),
      hide: () => setData({ loading: false, full: false }),
    }),
    [],
  );

  return !data.loading ? null : (
    <div className={`${data.full ? 'full-screen' : 'loading'}`}>
      <Container>
        <Row className="align-items-center">
          <Col>
            <div className="spinner">
              <div className="rect1" />
              <div className="rect2" />
              <div className="rect3" />
              <div className="rect4" />
              <div className="rect5" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

LoadingRoot.displayName = 'LoadingRoot';

LoadingRoot.defaultProps = {
  show: false,
  cover: false,
};

LoadingRoot.propTypes = {
  show: PropTypes.bool,
  cover: PropTypes.bool,
};

export default LoadingRoot;
