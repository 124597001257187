/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getAllTemplatesAction = createAction('APP_GET_ALL_TEMPLATES');
export const uploadTemplateAction = createAction('APP_UPLOAD_TEMPLATE');
export const updateDefaultTemplateAction = createAction('APP_UPDATE_DEFAULT_TEMPLATE');
export const deleteTemplateAction = createAction('APP_DELETE_TEMPLATE');
export const previewTemplateAction = createAction('APP_PREVIEW_TEMPLATE');
