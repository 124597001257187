import { handleActions } from 'redux-actions';

import {
  getAllTicketsAction,
  declineTicketAction,
  generateDocumentAction,
  clearGeneratedTemplateAction,
  removeExpiredTicketAction,
} from './TicketsActionConfig';
import {
  logoutAction,
} from '../auth/AuthActionConfig';
// reducers
const defaultState = {
  tickets: {},
  generatedTemplate: [],
};

const reducer = handleActions({
  [getAllTicketsAction](state, { payload }) {
    const groupedData = payload.tickets?.reduce((acc, item) => {
      if (!acc[item.status]) {
        acc[item.status] = [];
      }
      acc[item.status].push(item);
      return acc;
    }, {});

    return {
      ...state,
      tickets: {
        ...groupedData,
      },
      statusCounts: payload?.statusCounts,
    };
  },
  [generateDocumentAction](state, { payload }) {
    // Check if the payload ID is already present in the generatedTemplate array
    const isExist = state.generatedTemplate.some(item => item.id === payload.id);
    // If ID is not present, push it to the generatedTemplate array
    if (!isExist) {
      return {
        ...state,
        generatedTemplate: [
          ...state.generatedTemplate,
          payload, // Pushing the new payload into the array
        ],
      };
    }

    // If ID is already present, ignore it
    return state;
  },
  [clearGeneratedTemplateAction](state) {
    return {
      ...state,
      generatedTemplate: [],
    };
  },
  [declineTicketAction](state) {
    return state;
  },
  [removeExpiredTicketAction](state, { payload }) {
    const tickets = state.tickets?.pending?.filter((t) => t.id !== payload.id);
    return {
      ...state,
      tickets: {
        ...state.tickets,
        ...(tickets ? { pending: tickets } : { }),
      },
      statusCounts: {
        ...state.statusCounts,
        ...(state.statusCounts.pending && { pending: state.statusCounts.pending - 1 }),
      },
    };
  },
  [logoutAction]() {
    return defaultState;
  },
}, defaultState);

export default reducer;
