import * as React from 'react';
import { SVGProps } from 'react';

const BusinessInfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#6E7783"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m15.008 13.883-3.713-3.713M11.295 10.17 8.64 12.825a1.503 1.503 0 0 1-2.123 0l-3.18-3.18a1.503 1.503 0 0 1 0-2.122L8.64 2.22a1.503 1.503 0 0 1 2.122 0l3.18 3.18a1.503 1.503 0 0 1 0 2.123l-2.647 2.647ZM1.5 15.75H6M4.92 5.94l5.303 5.302"
    />
  </svg>
);
export default BusinessInfoIcon;
