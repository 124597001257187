/* eslint-disable import/prefer-default-export */
import { postRequest } from '@app/services/RequestService';

interface FileMetadata {
  name: string | null | undefined;
  type: string;
}

export const uploadToS3 = (
  type,
  file: Blob,
  metadata: FileMetadata,
// eslint-disable-next-line no-async-promise-executor
) => new Promise(async (resolve, reject) => {
  const filename = metadata.name;
  const fileType = metadata.type;
  // generate s3 url first
  try {
    const response = await postRequest(`upload/generate/${type}`, {
      filename,
      fileType,
    }).$promise;
    if (!response) {
      reject(new Error('No response'));
      return;
    }

    const result = response.data;

    // we're using fetch to remove connection with our api
    await fetch(result.url, {
      method: 'PUT',
      body: file,
    });
    // return the filename if uploading with s3 is successful
    resolve(result.filename);
  } catch (e) {
    reject(e);
  }
});

// DOWNLOAD FILES FROM S3-TEMP BUCKET
// eslint-disable-next-line no-async-promise-executor
export const downloadTempFile = (filename, type) => new Promise(async (resolve, reject) => {
  try {
    const response = await postRequest('download/temp', {
      filename,
      type,
    }).$promise;

    if (!response) {
      reject(new Error('No response'));
      return;
    }

    const downloadUrl = response.data;
    window.open(downloadUrl, '_blank', 'noreferrer');
    resolve(downloadUrl);
  } catch (e) {
    reject(e);
  }
});

// DOWNLOAD FILES FROM S3 MAIN BUCKET
// eslint-disable-next-line no-async-promise-executor
export const downloadFile = filename => new Promise(async (resolve, reject) => {
  try {
    const response = await postRequest('download', {
      filename,
    }).$promise;

    if (!response) {
      reject(new Error('No response'));
      return;
    }

    const downloadUrl = response.data;
    window.open(downloadUrl, '_blank', 'noreferrer');
    resolve(downloadUrl);
  } catch (e) {
    reject(e);
  }
});

// VIEW FILES FROM S3 MAIN BUCKET
// eslint-disable-next-line no-async-promise-executor
export const viewFile = filename => new Promise(async (resolve, reject) => {
  try {
    const response = await postRequest('view', {
      filename,
    }).$promise;

    if (!response) {
      reject(new Error('No response'));
      return;
    }

    // return s3 url
    resolve(response.data);
  } catch (e) {
    reject(e);
  }
});

// VIEW FILES FROM S3-TEMP BUCKET
// eslint-disable-next-line no-async-promise-executor
export const viewTempFile = filename => new Promise(async (resolve, reject) => {
  try {
    const response = await postRequest('view/temp', {
      filename,
    }).$promise;

    if (!response) {
      reject(new Error('No response'));
      return;
    }

    // return s3 url
    resolve(response.data);
  } catch (e) {
    reject(e);
  }
});
