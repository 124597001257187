import moment from 'moment';
import React, {
  UIEvent, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Viewer from 'react-viewer';
import {
  Button, Container, Navbar, Spinner,
} from 'reactstrap';

import ChatBg from '@assets/img/chat-bg.png';
import {
  deleteMessageDispatch,
  displayMessageDispatch, getTicketMessagesRequest, markTicketConversationAsReadRequest,
  sendMessageRequest, updateMessageDispatch,
} from '@reducers/ticket-conversations/TicketConversationAction';
import { getTicketRequest } from '@reducers/tickets/TicketsAction';
import { handleError } from '@services/ErrorHandler';
import { uploadToS3 } from '@services/FileService';
import LocaleService from '@services/LocaleService';
import { localDate } from '@shared/helpers';
import useMessaging from '@shared/hooks/useMessaging';
import { ArrowLeftIcon } from '@shared/icons';
import { CustomDocumentViewer } from '@shared/utils/CustomDocumentViewer/CustomDocumentViewer';
import UserAvatar from '@shared/utils/UserAvatar/UserAvatar';

import ChatBubble from './components/chat-bubble/ChatBubble';
import ChatInput from './components/chat-input/ChatInput';

import './TicketConversationPage.scss';
import classNames from 'classnames';

const conversationSelector = (ticketId: any) => (state: any) => state
  .TicketConversations.conversations[ticketId];

const paginationSelector = (ticketId: any) => (state: any) => state
  .TicketConversations.pagination[ticketId];

const TicketConversationPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const i18n = LocaleService.getTranslations('chat');

  const [ ticket, setTicket ] = useState<any>(location.state?.ticket ?? {});
  const [ loading, setLoading ] = useState(true);
  const [ showImage, setShowImage ] = useState(false);
  const [ images, setImages ] = useState<any[]>([]);

  const userId = useSelector((state: any) => state.Auth.user.id);
  const data = useSelector(conversationSelector(id)) ?? [];
  const meta = useSelector(paginationSelector(id)) ?? {};
  const topRef = useRef<any>(null);
  const bottomRef = useRef<any>(null);
  const scrollRef = useRef<any>(null);
  const currentScrollBottom = useRef<any>(null);

  const loadMessages = async () => {
    try {
      await dispatch(getTicketMessagesRequest(id as string, { page: 1 }));
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const loadData = async () => {
    try {
      loadMessages();
      const { data: resp } = await dispatch(getTicketRequest(id));
      setTicket(resp);
    } catch (err) {
      handleError(err);
    }
  };

  const markAsRead = async () => {
    try {
      await dispatch(markTicketConversationAsReadRequest(ticket.id));
    } catch {
      //
    }
  };

  const handleUpload = async (asset: File) => {
    const link = await uploadToS3('ticket_attachment', asset, {
      name: asset.name,
      type: asset.type,
    });

    return link;
  };

  const handleSendMessage = async (item: {
    message?: any;
    messageType: 'message' | 'image' | 'attachment'
    attachment?: any;
  }, itemId = null) => {
    const { message, messageType, attachment } = item;
    const tempId = itemId ?? Math.random();
    const messageData = {
      ticket_id: ticket.id,
      sender_id: userId,
      message,
      message_type: messageType,
      ...(item || {}),
      id: tempId,
      attachment: attachment ? URL.createObjectURL(attachment) : undefined,
      data: messageType === 'attachment' ? {
        filename: attachment.name,
        filesize: attachment.size,
      } : undefined,
      asset: attachment,
      status: 'sending',
      created_at: moment().format(),
    };

    const request = itemId ? updateMessageDispatch : displayMessageDispatch;
    await dispatch(request(messageData));
    bottomRef.current?.scrollIntoView();

    try {
      // upload first
      if (messageType !== 'message') {
        const link = await handleUpload(messageData.asset);
        messageData.attachment = link;
      }

      await dispatch(sendMessageRequest(ticket.id, messageData));
    } catch {
      dispatch(updateMessageDispatch({ id: tempId, ticket_id: ticket.id, status: 'error' }));
    }
  };

  const handleRetryMessage = (item: any) => {
    handleSendMessage({ ...item, attachment: item.asset }, item.id);
  };
  const handleDeleteMessage = (item: any) => {
    dispatch(deleteMessageDispatch({ ticket_id: id, id: item.id }));
  };
  const handleLoadMore = async () => {
    const { current_page: page, last_page: lastPage } = meta;
    if (loading || page >= lastPage) {
      return;
    }

    setLoading(true);
    try {
      await dispatch(getTicketMessagesRequest(ticket.id, { page: page + 1 }));
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        - scrollRef.current.clientHeight - currentScrollBottom.current;
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const onViewImage = (item: any) => {
    setImages([ { src: item.attachment } ]);
    setShowImage(true);
  };

  const onViewDocument = (item: any) => {
    CustomDocumentViewer.show({
      url: item.attachment,
      ...item.data,
      s3Filename: item.attachment_path,
      showDownload: true,
    });
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as any;
    const end = target.scrollTop === 0;

    const scrollBottom = target.scrollHeight - target.clientHeight - target.scrollTop;

    currentScrollBottom.current = scrollBottom;
    if (end) {
      handleLoadMore();
    }
  };

  const renderItem = (item: any, index: number) => {
    const prev = data[index - 1];
    const next = data[index + 1];
    return (
      <ChatBubble
        onDelete={() => handleDeleteMessage(item)}
        item={item}
        prevItem={prev}
        nextItem={next}
        onRetry={() => handleRetryMessage(item)}
        onViewImage={() => onViewImage(item)}
        onViewDocument={() => onViewDocument(item)}
      />
    );
  };

  useMessaging(({ data: d }) => {
    if (d.data.ticket_id === id && d.data.sender_id !== userId) {
      dispatch(displayMessageDispatch(d.data));

      setTimeout(() => {
        bottomRef.current?.scrollIntoView();
      }, 300);
    }
  }, {
    channel: id ? `private-ticket-chat-${id}` : null,
    event: 'ticket_message_sent',
  }, [ id ]);

  useEffect(() => {
    loadData();
    markAsRead();

    window.addEventListener('focus', markAsRead);
    return () => {
      window.removeEventListener('focus', markAsRead);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    bottomRef.current?.scrollIntoView();
  }, []);

  return (
    <div className="ticket-conversation-page">
      <Navbar sticky="top" container="md" className="py-4">
        <div className="d-flex align-items-center">
          <Button color="link" onClick={() => navigate(-1)}>
            <ArrowLeftIcon />
          </Button>
          <UserAvatar
            image={ticket.client?.image}
            user={ticket.client || {}}
            size={45}
          />
          <div className="ms-2">
            <h5 className="fw-bold mb-0">
              {ticket.client?.full_name}
            </h5>

            {ticket.client?.online_at && (
              <div className="text-online">
                {LocaleService.parseTranslation(i18n.label.onlineTime, {
                  ':time': localDate(ticket.client.online_at).fromNow(),
                })}
              </div>
            )}
          </div>
        </div>
      </Navbar>

      <div
        className={classNames('background chat-content', {
          'completed-chat': [ 'completed', 'expired', 'withdrawn' ].indexOf(ticket?.status) !== -1,
        })}
        style={{
          backgroundImage: `url(${ChatBg})`,
          margin: 0,
        }}
        onScroll={handleScroll}
        ref={scrollRef}
      >
        {loading && (
          <div className="text-center mt-3">
            <Spinner color="primary" size="sm" />
          </div>
        )}
        <Container fluid="md">
          <div ref={bottomRef} />
          {data.map(renderItem)}
          <div ref={topRef} />
        </Container>
      </div>

      {[ 'completed', 'expired', 'withdrawn' ].indexOf(ticket?.status) === -1
      && (
        <ChatInput
          onSendMessage={handleSendMessage}
        />
      )}

      <Viewer
        onClose={() => setShowImage(false)}
        visible={showImage}
        images={images}
        showTotal={false}
      />
    </div>
  );
};

export default TicketConversationPage;
