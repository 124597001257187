/* eslint-disable import/prefer-default-export */
import { useAppProvider } from '@app/AppProvider';
import LocaleService from '@services/LocaleService';

const translations = LocaleService.getTranslations('general.error');

const errorResponse = {
  401: translations.unauthorized,
  429: translations.throttle,
  422: translations.validation_error,
  500: translations.internal_server,
};

const parseErrorResponse = (response) => {
  // default message
  let message = translations.default;
  // means no internet or couldn't establish connection
  if (!response) {
    message = translations.establish_connection;
  // } else if (response.status === 422) {
  //   // validation error
  //   // temp: get the first error
  //   const { errors } = response.data;
  //   // eslint-disable-next-line prefer-destructuring
  //   message = {
  //     message: Object.values(errors)[0][0],
  //     title: translations.validation_error.title,
  //   };
  } else if (response.status === 400) {
    // eslint-disable-next-line prefer-destructuring
    message = {
      message: response.data.message,
      title: translations.invalid_request.title,
    };
  } else if (errorResponse[response.status]) {
    // if status code is defined in error response object
    message = errorResponse[response.status];
  }

  return message;
};

export const handleError = (err) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { toast } = useAppProvider();
  const { response } = err;
  const { title, message } = parseErrorResponse(response);
  // implement display of error here
  toast.error(title, message);
};

// eslint-disable-next-line no-console
export const logError = error => console.log('error in request', error);
