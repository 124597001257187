import React, { useRef, useEffect } from 'react';
import { ToastContainer, toast, Bounce } from 'react-toastify';

import CustomDocumentViewerComponent from '@shared/utils/CustomDocumentViewer/CustomDocumentViewer';
import LoadingRoot from '@shared/utils/Loading/Loading';
import 'react-toastify/dist/ReactToastify.css';

interface AppProviderProps {
  children: object;
}

// this should be transfer in loading root
type LoadingService = {
  show: () => void;
  hide: () => void;
};
// this should be transfer in custom toast
type NotificationService = {
  success: (title: string, message: string) => void;
  error: (title: string, message: string) => void;
};

type Services = {
  loading: LoadingService;
  toast: NotificationService;
};

const services: Services = {
  loading: {
    show: () => {},
    hide: () => {},
  },
  toast: {
    success: () => {},
    error: () => {},
  },
};

const useAppProvider = () => services;

const AppProvider = ({ children }: AppProviderProps) => {
  const loadingRef = useRef<LoadingService>({ show: () => {}, hide: () => {} });

  useEffect(() => {
    services.loading = loadingRef.current;
    services.toast = {
      success: (title: string, message: string) => {
        toast.success((
          <div>
            <div className="s2">{title}</div>
            <div>{message}</div>
          </div>
        ));
      },
      error: (title: string, message: string) => {
        toast.error((
          <div>
            <div className="s2" style={{ color: '#E30000' }}>{title}</div>
            <div>{message}</div>
          </div>
        ));
      },
    };
  }, []);

  return (
    <>
      {children}
      <LoadingRoot ref={loadingRef} />
      <CustomDocumentViewerComponent />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </>
  );
};

export { useAppProvider };

export default AppProvider;
