import * as React from 'react';
import { SVGProps } from 'react';

interface ProgressIconProps extends SVGProps<SVGSVGElement> {
  rectWidth?: any;
  rectHeight?: any;
}

const ProgressIcon = ({
  fill, width, rectWidth, rectHeight, height, ...props
}: ProgressIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 95}
    height={height || 9}
    fill="none"
    {...props}
  >
    <rect width={rectWidth || 94.333} height={rectHeight || 8} y={0.817} fill={fill || '#DEE8F5'} rx={4} />
  </svg>
);

export default ProgressIcon;
