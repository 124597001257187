import React from 'react';

import './UserAvatar.scss';

const UserAvatar = ({ image, user, size = 30 }: {
  image?: string;
  user: any;
  size?: string | number
}) => (
  <div
    className="user-avatar"
    style={{
      width: size,
      height: size,
    }}
  >
    {image ? (
      <img src={image} alt="avatar" width={size} height={size} />
    ) : (
      <div>
        {user.first_name?.charAt(0)}{user.last_name?.charAt(0)}
      </div>
    )}
  </div>
);

export default UserAvatar;
