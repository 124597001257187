import * as React from 'react';
import { SVGProps } from 'react';

function PaidIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="45"
      height="18"
      viewBox="0 0 45 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="44" height="17" rx="5.5" stroke="#109173" />
      <path d="M8.6875 7.36667V8.55834L8.26667 8.4125C8.05417 8.3375 7.925 8.26667 7.925 7.90417C7.925 7.60834 8.14584 7.36667 8.41667 7.36667H8.6875Z" fill="#109173" />
      <path d="M10.075 10.0958C10.075 10.3917 9.85417 10.6333 9.58333 10.6333H9.3125V9.44167L9.73333 9.5875C9.94583 9.6625 10.075 9.73333 10.075 10.0958Z" fill="#109173" />
      <path d="M10.7458 4.83333H7.25417C5.7375 4.83333 4.83334 5.73749 4.83334 7.25416V10.7458C4.83334 12.2625 5.7375 13.1667 7.25417 13.1667H10.7458C12.2625 13.1667 13.1667 12.2625 13.1667 10.7458V7.25416C13.1667 5.73749 12.2625 4.83333 10.7458 4.83333ZM9.94167 8.99999C10.2667 9.11249 10.7 9.34999 10.7 10.0958C10.7 10.7375 10.2 11.2583 9.58334 11.2583H9.3125V11.5C9.3125 11.6708 9.17084 11.8125 9 11.8125C8.82917 11.8125 8.6875 11.6708 8.6875 11.5V11.2583H8.5375C7.85417 11.2583 7.3 10.6792 7.3 9.97083C7.3 9.79999 7.4375 9.65833 7.6125 9.65833C7.78334 9.65833 7.925 9.79999 7.925 9.97083C7.925 10.3375 8.2 10.6333 8.5375 10.6333H8.6875V9.22083L8.05834 8.99999C7.73334 8.8875 7.3 8.64999 7.3 7.90416C7.3 7.26249 7.8 6.74166 8.41667 6.74166H8.6875V6.49999C8.6875 6.32916 8.82917 6.18749 9 6.18749C9.17084 6.18749 9.3125 6.32916 9.3125 6.49999V6.74166H9.4625C10.1458 6.74166 10.7 7.32083 10.7 8.02916C10.7 8.19999 10.5625 8.34166 10.3875 8.34166C10.2167 8.34166 10.075 8.19999 10.075 8.02916C10.075 7.66249 9.8 7.36666 9.4625 7.36666H9.3125V8.77916L9.94167 8.99999Z" fill="#109173" />
      <path d="M18.4307 10.0928V9.1748H20.3545C20.8298 9.1748 21.1976 9.05273 21.458 8.80859C21.7217 8.5612 21.8535 8.2194 21.8535 7.7832V7.77344C21.8535 7.33073 21.7217 6.9873 21.458 6.74316C21.1976 6.49902 20.8298 6.37695 20.3545 6.37695H18.4307V5.4541H20.6279C21.0902 5.4541 21.4954 5.55176 21.8438 5.74707C22.1953 5.93913 22.4704 6.20931 22.6689 6.55762C22.8675 6.90267 22.9668 7.30469 22.9668 7.76367V7.77344C22.9668 8.23242 22.8675 8.6377 22.6689 8.98926C22.4704 9.33757 22.1953 9.60938 21.8438 9.80469C21.4954 9.99674 21.0902 10.0928 20.6279 10.0928H18.4307ZM17.8838 12.5V5.4541H18.9775V12.5H17.8838ZM23.377 12.5L25.9209 5.4541H26.8047V6.68945H26.4727L24.5293 12.5H23.377ZM24.6025 10.5957L24.9102 9.7168H28.1182L28.4258 10.5957H24.6025ZM28.4941 12.5L26.5557 6.68945V5.4541H27.1074L29.6465 12.5H28.4941ZM30.8721 12.5V5.4541H31.9658V12.5H30.8721ZM33.7334 12.5V5.4541H36.2773C36.9772 5.4541 37.5745 5.59245 38.0693 5.86914C38.5641 6.14583 38.9417 6.5446 39.2021 7.06543C39.4658 7.58626 39.5977 8.21615 39.5977 8.95508V8.96484C39.5977 9.71029 39.4658 10.3467 39.2021 10.874C38.9417 11.4014 38.5641 11.805 38.0693 12.085C37.5778 12.3617 36.9805 12.5 36.2773 12.5H33.7334ZM34.8271 11.5527H36.1553C36.6468 11.5527 37.0651 11.4518 37.4102 11.25C37.7585 11.0482 38.0221 10.7552 38.2012 10.3711C38.3835 9.98698 38.4746 9.52311 38.4746 8.97949V8.96973C38.4746 8.42936 38.3818 7.96712 38.1963 7.58301C38.014 7.19889 37.7503 6.90592 37.4053 6.7041C37.0602 6.50228 36.6436 6.40137 36.1553 6.40137H34.8271V11.5527Z" fill="#109173" />
    </svg>

  );
}
export default PaidIcon;
