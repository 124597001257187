import * as React from 'react';
import { SVGProps } from 'react';

const SignatureIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#6E7783"
      d="m3.307 13.828-.002-.002c-.26-.246-.408-.638-.356-1.102l.278-2.43c.018-.162.082-.376.181-.591.1-.216.222-.398.332-.511l.006-.006 6.157-6.518c.715-.756 1.383-1.08 2.002-1.098.62-.017 1.305.269 2.062.983.756.715 1.08 1.384 1.098 2.002.017.62-.269 1.305-.983 2.062l-6.158 6.517-.002.002c-.24.258-.725.519-1.073.576h-.003l-2.389.408a5.83 5.83 0 0 0-.144.011 1.595 1.595 0 0 1-.158.009 1.22 1.22 0 0 1-.848-.312ZM9.994 2.739 3.838 9.263h-.001c-.13.137-.237.326-.311.486a2.012 2.012 0 0 0-.174.54l-.001.012-.277 2.426v.002c-.043.353.034.732.318 1 .285.27.665.317 1.003.265l.01-.001 2.414-.413c.186-.032.385-.118.542-.202.157-.085.337-.202.464-.336v-.001l6.158-6.518h.001c.48-.512.906-1.099.948-1.796.044-.724-.33-1.405-1.058-2.09-.644-.62-1.28-.954-1.927-.954-.797 0-1.426.498-1.953 1.056Z"
    />
    <path
      stroke="#6E7783"
      d="M13.024 7.593a.056.056 0 0 1 .039.021c.01.012.014.025.015.04a.081.081 0 0 1-.026.039.073.073 0 0 1-.047.02H12.977a4.644 4.644 0 0 1-4.113-3.909.071.071 0 0 1 .055-.077.072.072 0 0 1 .074.06 4.53 4.53 0 0 0 4.03 3.806Z"
    />
    <path
      fill="#292D32"
      stroke="#6E7783"
      d="M15.75 16.563H2.25a.061.061 0 0 1-.043-.02.061.061 0 0 1-.02-.043c0-.013.006-.029.02-.043a.061.061 0 0 1 .043-.02h13.5c.013 0 .029.006.043.02.014.014.02.03.02.043a.061.061 0 0 1-.02.043.061.061 0 0 1-.043.02Z"
    />
  </svg>

);
export default SignatureIcon;
