/* eslint-disable no-console */
import { handleActions, combineActions } from 'redux-actions';

import {
  loginAction,
  logoutAction,
  clearUserTokenAction,
  // forgot password
  getUserDetailsAction,
  verifyCodeAction,
  sendCodeAction,
  registerAction,
  changeForgotPasswordAction,
  setLawyerSignatureAction,
  editProfileAction,
  editBusinessInformationAction,
  editAttorneyFeeAction,
  editAllowExpeditedCaseAction,
} from './AuthActionConfig';

// reducers
const defaultState = {
  accessToken: null,
  user: {},
  errors: {},
  verification: {},
};

const reducer = handleActions({
  [combineActions(loginAction, registerAction)](state, { payload }) {
    return {
      ...state,
      accessToken: payload.access_token,
      user: payload.user,
    };
  },
  [logoutAction]() {
    return defaultState;
  },
  [getUserDetailsAction](state, { payload }) {
    return {
      ...state,
      user: payload,
    };
  },
  [clearUserTokenAction]() {
    return defaultState;
  },
  [sendCodeAction]: (state, { params, payload }) => {
    const { codeValidityInSeconds } = payload;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { email_or_mobile } = params;
    const { verification } = state;

    return {
      ...state,
      verification: {
        ...verification,
        email_or_mobile,
        codeValidityInSeconds,
        timestamp: new Date(),
      },
    };
  },
  [changeForgotPasswordAction.toString()]: (state) => ({
    ...state,
    verification: defaultState.verification,
  }),
  [verifyCodeAction]: (state, { payload, params }) => {
    const { verification_code: verificationCode } = params;
    const verificationId = payload;
    const { verification } = state;

    return {
      ...state,
      verification: {
        ...verification,
        verification_code: verificationCode,
        verification_id: verificationId,
        timestamp: null,
      },
    };
  },
  [setLawyerSignatureAction](state, { payload }) {
    const { path, url } = payload;
    const { user } = state;

    return {
      ...state,
      user: {
        ...user,
        signature: path,
        signature_url: url,
      },
    };
  },
  [editProfileAction]: (state, { payload }) => {
    const { user } = state;
    return {
      ...state,
      user: {
        ...user,
        ...payload,
      },
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  [editBusinessInformationAction]: (state, { payload }) => {
    const { business } = state.user;
    return {
      ...state,
      user: {
        business: {
          ...business,
          ...payload,
        },
      },
    };
  },

  [editAttorneyFeeAction]: (state, { payload }) => ({
    ...state,
    user: {
      ...state.user,
      business: {
        ...state.user.business,
        business_fees: state?.user?.business?.business_fees?.map((fee) => (fee.type === 'attorney_fee' ? payload?.updatedBusinessFee : fee),
        ),
      },
    },
  }),

  [editAllowExpeditedCaseAction]: (state, { payload }) => ({
    ...state,
    user: {
      ...state.user,
      business: {
        ...state.user.business,
        allow_expedited_case: payload,
      },
    },
  }),

}, defaultState);

export default reducer;
