import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import LocaleService from '@services/LocaleService';
import { formatBytes } from '@shared/helpers';
import {
  CheckIcon, DocumentIcon, RefreshIcon, SentIcon,
} from '@shared/icons';

import './ChatAttachment.scss';

const ChatAttachment = ({
  data, sent, sending, failed, date, onRetry, onViewDocument,
}: {
  data: any;
  sent: boolean;
  sending: boolean;
  failed: boolean;
  date: string;
  onRetry: () => void
  onViewDocument: () => void
}) => {
  const i18n = LocaleService.getTranslations('chat');

  return (
    <div className={classNames('chat-attachment', {
      sent,
    })}
    >
      <div
        className={classNames('chat-container cursor-pointer', {
          sent,
          received: !sent,
          failed,
        })}
        onClick={failed ? onRetry : onViewDocument}
      >
        <div className="icon">
          <DocumentIcon width={17} />
        </div>
        <div>
          <div className="file-name">
            {data.filename}
          </div>
          <div className="file-size">
            {formatBytes(data.filesize)}
          </div>
        </div>
      </div>

      {failed ? (
        <div className="d-flex align-items-center retry">
          {i18n.button.retry}
          <RefreshIcon width={10} className="ms-1" />
        </div>
      ) : (
        <div className="timestamp">
          {moment.utc(date).local().format('hh:mm A')}
          {sent && sending && (
            <CheckIcon className="icon-check" />
          )}
          {sent && !sending && (
            <SentIcon className="icon-sent" />
          )}
        </div>
      )}
    </div>
  );
};

export default ChatAttachment;
