import storage from 'redux-persist/lib/storage';

const reduxPersist = {
  auth: {
    key: 'Auth',
    storage,
    whitelist: [ 'accessToken', 'user' ],
  },
};

export default reduxPersist;
