import * as React from 'react';
import { SVGProps } from 'react';

function ImageIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.36498 17.4258L2.34665 17.4442C2.09915 16.9033 1.94332 16.2892 1.87915 15.6108C1.94332 16.28 2.11748 16.885 2.36498 17.4258Z" fill="#6E7783" />
      <path d="M8.25003 9.51501C9.45493 9.51501 10.4317 8.53824 10.4317 7.33334C10.4317 6.12844 9.45493 5.15167 8.25003 5.15167C7.04512 5.15167 6.06836 6.12844 6.06836 7.33334C6.06836 8.53824 7.04512 9.51501 8.25003 9.51501Z" fill="#6E7783" />
      <path d="M14.8408 1.83331H7.15915C3.82248 1.83331 1.83331 3.82248 1.83331 7.15915V14.8408C1.83331 15.84 2.00748 16.7108 2.34665 17.4441C3.13498 19.1858 4.82165 20.1666 7.15915 20.1666H14.8408C18.1775 20.1666 20.1666 18.1775 20.1666 14.8408V12.7416V7.15915C20.1666 3.82248 18.1775 1.83331 14.8408 1.83331ZM18.6725 11.4583C17.9575 10.8441 16.8025 10.8441 16.0875 11.4583L12.2741 14.7308C11.5591 15.345 10.4041 15.345 9.68915 14.7308L9.37748 14.4741C8.72665 13.9058 7.69081 13.8508 6.95748 14.3458L3.52915 16.6466C3.32748 16.1333 3.20831 15.5375 3.20831 14.8408V7.15915C3.20831 4.57415 4.57415 3.20831 7.15915 3.20831H14.8408C17.4258 3.20831 18.7916 4.57415 18.7916 7.15915V11.5591L18.6725 11.4583Z" fill="#6E7783" />
    </svg>
  );
}
export default ImageIcon;
