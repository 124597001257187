import * as React from 'react';
import { SVGProps } from 'react';

function CameraIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M33.75 11.25C32.6063 11.25 31.5563 10.5938 31.0313 9.58125L29.6813 6.8625C28.8188 5.15625 26.5688 3.75 24.6563 3.75H20.3625C18.4313 3.75 16.1813 5.15625 15.3188 6.8625L13.9688 9.58125C13.4438 10.5938 12.3938 11.25 11.25 11.25C7.18126 11.25 3.95626 14.6812 4.21876 18.7312L5.19376 34.2188C5.41876 38.0812 7.50001 41.25 12.675 41.25H32.325C37.5 41.25 39.5625 38.0812 39.8063 34.2188L40.7813 18.7312C41.0438 14.6812 37.8188 11.25 33.75 11.25ZM19.6875 13.5938H25.3125C26.0813 13.5938 26.7188 14.2312 26.7188 15C26.7188 15.7688 26.0813 16.4062 25.3125 16.4062H19.6875C18.9188 16.4062 18.2813 15.7688 18.2813 15C18.2813 14.2312 18.9188 13.5938 19.6875 13.5938ZM22.5 33.975C19.0125 33.975 16.1625 31.1437 16.1625 27.6375C16.1625 24.1313 18.9938 21.3 22.5 21.3C26.0063 21.3 28.8375 24.1313 28.8375 27.6375C28.8375 31.1437 25.9875 33.975 22.5 33.975Z" fill="#FEFEFE" />
    </svg>
  );
}
export default CameraIcon;
