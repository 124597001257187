import * as React from 'react';
import { SVGProps } from 'react';

function FileIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.6212 18.2939C11.8731 18.3525 11.8961 18.6824 11.6508 18.7642L10.2025 19.2408C6.56334 20.4142 4.64751 19.4333 3.46501 15.7942L2.29167 12.1733C1.11834 8.53416 2.09001 6.60916 5.72917 5.43582L6.12484 5.30479C6.5277 5.17137 6.92137 5.57603 6.80956 5.98542C6.76605 6.14475 6.72386 6.30988 6.68251 6.48082L5.78417 10.3217C4.77584 14.6392 6.25167 17.0225 10.5692 18.0492L11.6212 18.2939Z" fill="#6E7783" />
      <path d="M15.7392 2.94253L14.2084 2.58503C11.1467 1.86086 9.32252 2.45669 8.25002 4.67503C7.97502 5.23419 7.75502 5.91253 7.57169 6.69169L6.67336 10.5325C5.77502 14.3642 6.95752 16.2525 10.78 17.16L12.32 17.5267C12.8517 17.655 13.3467 17.7375 13.805 17.7742C16.665 18.0492 18.1867 16.7109 18.9567 13.4017L19.855 9.57003C20.7534 5.73836 19.58 3.84086 15.7392 2.94253ZM14.0159 12.2192C13.9334 12.5309 13.6584 12.7325 13.3467 12.7325C13.2917 12.7325 13.2367 12.7234 13.1725 12.7142L10.505 12.0359C10.1384 11.9442 9.91836 11.5684 10.01 11.2017C10.1017 10.835 10.4775 10.615 10.8442 10.7067L13.5117 11.385C13.8875 11.4767 14.1075 11.8525 14.0159 12.2192ZM16.7017 9.12086C16.6192 9.43253 16.3442 9.6342 16.0325 9.6342C15.9775 9.6342 15.9225 9.62503 15.8584 9.61586L11.4125 8.48836C11.0459 8.39669 10.8259 8.02086 10.9175 7.65419C11.0092 7.28753 11.385 7.06753 11.7517 7.15919L16.1975 8.28669C16.5734 8.36919 16.7934 8.74503 16.7017 9.12086Z" fill="#6E7783" />
    </svg>
  );
}
export default FileIcon;
