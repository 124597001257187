import * as React from 'react';
import { SVGProps } from 'react';

const PasswordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#6E7783"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.5 7.5V6c0-2.482.75-4.5 4.5-4.5s4.5 2.018 4.5 4.5v1.5M12.75 16.5h-7.5c-3 0-3.75-.75-3.75-3.75v-1.5c0-3 .75-3.75 3.75-3.75h7.5c3 0 3.75.75 3.75 3.75v1.5c0 3-.75 3.75-3.75 3.75Z"
    />
    <path
      stroke="#6E7783"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.997 12h.007M8.997 12h.006M5.996 12h.007"
    />
  </svg>
);
export default PasswordIcon;
