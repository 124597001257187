/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getCountiesAction = createAction('APP_GET_COUNTIES');
export const getStatesAction = createAction('APP_GET_STATES');
export const getFederalAdmissionsAction = createAction('APP_GET_FEDERAL_ADMISSIONS');
export const getPracticeAreasAction = createAction('APP_GET_PRACTICE_AREAS');
export const getExpeditedServiceCostAction = createAction('APP_GET_SERVICES');
