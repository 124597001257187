/* eslint-disable no-console */
import Pusher from 'pusher-js';

import config from '@app/config/app';

const { pusher: pusherConfig } = config;

let instance: Pusher | undefined;
const initialize = (accessToken: string) => {
  if (instance) {
    instance.disconnect();
  }

  instance = new Pusher(pusherConfig.key as string, {
    cluster: pusherConfig.cluster as string,
    authEndpoint: pusherConfig.authEndpoint,
    auth: {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });
  return instance;
};

const getInstance = (accessToken: string) => {
  if (!instance) {
    return initialize(accessToken);
  }
  return instance;
};

const clearInstance = () => {
  if (instance) {
    instance.disconnect();
    instance = undefined;
  }
};

export default {
  initialize,
  getInstance,
  clearInstance,
};
