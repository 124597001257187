import * as React from 'react';
import { SVGProps } from 'react';

const TimeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#6E7783"
      d="M9 17.063C4.553 17.063.937 13.447.937 9 .938 4.553 4.553.937 9 .937c4.447 0 8.063 3.615 8.063 8.063 0 4.447-3.615 8.063-8.063 8.063Zm0-15A6.946 6.946 0 0 0 2.062 9 6.946 6.946 0 0 0 9 15.938 6.946 6.946 0 0 0 15.938 9 6.946 6.946 0 0 0 9 2.062Z"
    />
    <path
      fill="#6E7783"
      d="M11.783 11.947a.502.502 0 0 1-.285-.082l-2.325-1.388c-.578-.345-1.005-1.102-1.005-1.77V5.632c0-.307.255-.562.562-.562.307 0 .563.255.563.563v3.074c0 .27.225.668.457.803l2.325 1.388c.27.157.353.502.195.772a.578.578 0 0 1-.487.277Z"
    />
  </svg>
);
export default TimeIcon;
