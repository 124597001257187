import * as React from 'react';
import { SVGProps } from 'react';

function RefreshIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5.50003 10.6458C4.86114 10.6458 4.26253 10.525 3.7042 10.2833C3.14587 10.0416 2.65906 9.71316 2.24378 9.29789C1.82852 8.88262 1.50005 8.39581 1.25838 7.83748C1.01671 7.27915 0.895874 6.68054 0.895874 6.04165C0.895874 5.40276 1.01671 4.80416 1.25838 4.24582C1.50005 3.68749 1.82852 3.20069 2.24378 2.78541C2.65906 2.37014 3.14587 2.04167 3.7042 1.80001C4.26253 1.55833 4.86114 1.4375 5.50003 1.4375H5.64378L5.05835 0.852079C4.97988 0.773609 4.94065 0.67986 4.94065 0.570831C4.94065 0.461812 4.97988 0.365287 5.05835 0.281256C5.13891 0.200702 5.23456 0.159039 5.34533 0.156267C5.45609 0.153487 5.55348 0.192374 5.6375 0.272928L6.86562 1.49584C6.95799 1.58819 7.00417 1.70242 7.00417 1.83853C7.00417 1.97465 6.95799 2.08888 6.86562 2.18125L5.64793 3.40414C5.56737 3.48817 5.47172 3.5288 5.36095 3.52602C5.25019 3.52323 5.1528 3.47983 5.06878 3.39581C4.9903 3.31178 4.95106 3.21526 4.95106 3.10624C4.95106 2.99721 4.9903 2.90346 5.06878 2.82499L5.64378 2.24998H5.50003C4.44378 2.24998 3.54777 2.61787 2.81201 3.35363C2.07624 4.08939 1.70836 4.9854 1.70836 6.04165C1.70836 7.0979 2.07624 7.99391 2.81201 8.72967C3.54777 9.46544 4.44378 9.83332 5.50003 9.83332C6.47781 9.83332 7.32503 9.50884 8.0417 8.85988C8.75836 8.21091 9.16774 7.40032 9.26982 6.4281C9.2844 6.31838 9.33197 6.22654 9.41253 6.15258C9.49308 6.07863 9.58822 6.04165 9.69793 6.04165C9.80765 6.04165 9.90279 6.07724 9.98335 6.14841C10.0639 6.2196 10.0986 6.30727 10.0875 6.41143C9.9889 7.6142 9.50175 8.62063 8.62605 9.4307C7.75037 10.2408 6.70836 10.6458 5.50003 10.6458Z" fill="#E30000" />
    </svg>
  );
}
export default RefreshIcon;
