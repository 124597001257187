import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import persistConfig from '@config/redux-persist';
import AppReducer from '@reducers/app/AppReducer';
import AuthReducer from '@reducers/auth/AuthReducer';
import LoadingReducer from '@reducers/loading/LoadingReducer';
import TemplatesReducer from '@reducers/templates/TemplatesReducer';
import TicketConversationReducer from '@reducers/ticket-conversations/TicketConversationReducer';
import TicketReducer from '@reducers/tickets/TicketsReducer';

const reducers = combineReducers({
  Auth: persistReducer(persistConfig.auth, AuthReducer),
  App: AppReducer,
  Loading: LoadingReducer,
  Tickets: TicketReducer,
  TicketConversations: TicketConversationReducer,
  Templates: TemplatesReducer,
});

export default reducers;
