import * as React from 'react';
import { SVGProps } from 'react';

const DollarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#EC4545"
      d="M7.562 5.384v1.842l-.65-.225c-.328-.116-.528-.226-.528-.786 0-.457.341-.83.76-.83h.418ZM9.707 9.602c0 .457-.341.831-.76.831H8.53V8.591l.65.226c.328.116.528.225.528.785Z"
    />
    <path
      fill="#EC4545"
      d="M10.744 1.47H5.348c-2.344 0-3.741 1.397-3.741 3.74v5.397c0 2.344 1.397 3.74 3.74 3.74h5.397c2.344 0 3.741-1.396 3.741-3.74V5.21c0-2.344-1.397-3.741-3.741-3.741ZM9.5 7.909c.502.174 1.172.54 1.172 1.693 0 .992-.773 1.797-1.726 1.797H8.53v.373a.486.486 0 0 1-.483.483.486.486 0 0 1-.483-.483V11.4h-.232c-1.056 0-1.912-.895-1.912-1.99a.482.482 0 1 1 .966 0c0 .567.425 1.024.946 1.024h.232V8.25l-.972-.341c-.503-.174-1.172-.541-1.172-1.694 0-.991.772-1.796 1.725-1.796h.419v-.374c0-.264.219-.483.483-.483s.483.22.483.483v.374h.232c1.056 0 1.912.895 1.912 1.99a.482.482 0 1 1-.966 0c0-.567-.425-1.024-.946-1.024h-.232v2.182l.972.342Z"
    />
  </svg>
);
export default DollarIcon;
