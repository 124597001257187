import pluralize from 'pluralize';
import LocalizedStrings from 'react-localization';

import availableLocales from '@locale/available-locales';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const locale = new LocalizedStrings(availableLocales);

// flat parsing
const parseTranslationElement = (text, data) => {
  if (!data) return text;

  // create a regexp first
  const splitRegExString = [];
  const expectedStrings = {};
  Object.entries(data).forEach(([ key, value ]) => {
    // eslint-disable-next-line no-useless-escape
    const regExString = `<${key}>(.*?)<\/${key}>`;
    // push the split regex
    splitRegExString.push(regExString);

    // then get the child text to convert it later
    const regEx = new RegExp(regExString, 'g');
    const foundText = text.match(regEx);

    if (!foundText) return;

    // if there is 2 text with same element loop through them
    foundText.map((foundTextValue) => {
      // child text inside the element tag
      let childText = foundTextValue.replace(`<${key}>`, '');
      childText = childText.replace(`</${key}>`, '');

      // after getting the child text store the key as temp in the arr
      // and the callback provided
      expectedStrings[childText] = value;

      return foundTextValue;
    });
  });

  const splitRegex = new RegExp(splitRegExString.join('|'), 'g');
  const splitText = text.split(splitRegex);

  return splitText.map((convertedText) => {
    if (expectedStrings[convertedText]) {
      return expectedStrings[convertedText](convertedText);
    }

    return convertedText;
  });
};

// simple parsing to support nested
// const simpleParseTranslationElement = (text, key, value) => {
//   // eslint-disable-next-line no-useless-escape
//   const regExString = `<${key}>(.*?)<\/${key}>`;

//   // then get the child text to convert it later
//   const regEx = new RegExp(regExString, 'g');
//   const foundText = text.split(regEx);

//   if(!foundText) return;
//   console.log('found text', foundText);
//   // if there is 2 text with same element loop through them
//   return foundText.map((foundTextValue) => {
//     // child text inside the element tag
//     let childText = foundTextValue.replace(`<${key}>`, '');
//     childText = childText.replace(`</${key}>`, '');
//     console.log('child text - -- ', childText, ' aaa', childText.trim() !== '');

//     return childText.trim() !== '' ? value(childText) : foundTextValue;
//   });
// };

const parseTranslation = (text, data) => {
  if (!data) return text;

  let parsedText = text;

  Object.entries(data).forEach(([ key, value ]) => {
    // disable temporary since it shows object object when combined with text
    // if (typeof value === 'function') {
    //   parsedText = parseTranslationElement(parsedText, key, value);
    // }

    parsedText = parsedText.replace(key, value);
  });
  // expected return is array to also support element transition
  return parsedText;
};

const getTranslations = (translation, data) => parseTranslation(
  // eslint-disable-next-line no-eval
  eval(`locale.${translation}`),
  data,
);

// eslint-disable-next-line max-len
const getPluralizedTranslation = (word, count, inclusive = true) => pluralize(word, count, inclusive);

export default {
  getTranslations,
  parseTranslation,
  parseTranslationElement,
  getPluralizedTranslation,
  // simpleParseTranslationElement,
};
