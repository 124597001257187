import * as React from 'react';
import { SVGProps } from 'react';

const FeesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#6E7783"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.504 10.747c0 .968.742 1.748 1.665 1.748h1.882c.803 0 1.455-.683 1.455-1.523 0-.915-.397-1.237-.99-1.447l-3.022-1.05c-.593-.21-.99-.533-.99-1.448 0-.84.652-1.522 1.455-1.522H9.84c.923 0 1.665.78 1.665 1.747M9 4.5v9"
    />
    <path
      stroke="#6E7783"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.25 16.5h-4.5C3 16.5 1.5 15 1.5 11.25v-4.5C1.5 3 3 1.5 6.75 1.5h4.5C15 1.5 16.5 3 16.5 6.75v4.5c0 3.75-1.5 5.25-5.25 5.25Z"
    />
  </svg>
);
export default FeesIcon;
