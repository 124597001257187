import * as React from 'react';
import { SVGProps } from 'react';

function ChangePasswordShieldIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="186" height="175" viewBox="0 0 186 175" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2695_1027)">
        <path d="M113.92 175C128.338 170.146 141.878 163.569 154.133 155.464C161.852 150.359 169.23 144.433 173.166 136.796C181.76 120.101 171.817 100.369 176.788 82.6279C178.464 76.637 181.802 71.068 183.631 65.1128C189.236 46.8487 178.409 25.8864 158.848 17.114C149.62 12.9536 139.155 11.4499 129.045 9.28654C113.524 5.96419 98.3164 1.00147 82.3507 0.127789C68.7702 -0.603247 54.7586 1.77411 43.2503 7.98494C31.742 14.1958 23.0082 24.4422 20.936 35.9188C19.5453 43.5561 21.0751 51.4073 19.7886 59.0564C17.6261 71.9001 7.87006 82.83 3.46144 95.2576C0.992647 102.371 0.294469 109.847 1.41188 117.205C2.52929 124.563 5.43751 131.64 9.94921 137.979C17.7373 148.772 29.9549 156.861 43.3268 162.382C56.6987 167.904 71.204 171.036 85.7441 173.562" fill="#F5FBFF" />
        <path d="M88.2693 29.4678C88.2693 29.4678 47.7512 46.069 43.4099 47.8735C39.0687 49.678 36.8981 54.0087 36.8981 58.3395C36.8981 62.6702 33.6422 119.181 88.2693 146.503C88.2693 146.503 93.2273 147.872 96.9888 146.503C100.75 145.135 148.504 122.037 148.504 58.159C148.504 58.159 148.504 51.6629 140.907 48.0539C133.31 44.445 96.5786 29.4678 96.5786 29.4678C96.5786 29.4678 92.0679 27.4829 88.2693 29.4678Z" fill="#3183DD" />
        <path opacity="0.1" d="M92.6825 42.5299V131.076C92.6825 131.076 51.853 111.449 52.2144 59.8498L92.6825 42.5299Z" fill="black" />
        <path d="M83.6209 111C82.1572 111 80.7667 110.418 79.7422 109.401L62.0315 91.8076C59.9091 89.6993 59.9091 86.2098 62.0315 84.1015C64.1538 81.9933 67.6667 81.9933 69.789 84.1015L83.6209 97.8416L115.09 66.5812C117.213 64.4729 120.726 64.4729 122.848 66.5812C124.97 68.6895 124.97 72.179 122.848 74.2872L87.4997 109.401C86.4751 110.418 85.0846 111 83.6209 111Z" fill="#FEFEFE" />
      </g>
      <defs>
        <clipPath id="clip0_2695_1027">
          <rect width="184.211" height="175" fill="white" transform="translate(0.894775)" />
        </clipPath>
      </defs>
    </svg>

  );
}
export default ChangePasswordShieldIcon;
