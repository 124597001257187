import * as React from 'react';
import { SVGProps } from 'react';

const SubtractIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={3}
    fill="none"
    {...props}
  >
    <path
      stroke="#8F9CB0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.5 1.857h-12"
    />
  </svg>
);
export default SubtractIcon;
