import * as React from 'react';
import { SVGProps } from 'react';

function SendIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.5642 7.80084L8.7175 3.8775C3.44666 1.2375 1.28333 3.40083 3.92333 8.67167L4.72083 10.2667C4.95 10.7342 4.95 11.275 4.72083 11.7425L3.92333 13.3283C1.28333 18.5992 3.4375 20.7625 8.7175 18.1225L16.5642 14.1992C20.0842 12.4392 20.0842 9.56084 16.5642 7.80084Z" fill="#6E7783" />
    </svg>
  );
}
export default SendIcon;
