/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getAllTicketsAction = createAction('GET_PENDING_TICKETS');
export const declineTicketAction = createAction('DECLINE_TICKET');
export const acceptTicketAction = createAction('ACCEPT_TICKET');
export const getUploadedTemplateListAction = createAction('GET_UPLOADED_TEMPLATE_LIST');
export const generateDocumentAction = createAction('GENERATE_DOCUMENT');
export const getTicketAction = createAction('GET_TICKET');

export const clearGeneratedTemplateAction = createAction('CLEAR_GENERATED_TEMPLATE');
export const removeExpiredTicketAction = createAction('REMOVED_TICKET');
export const sendTicketPleaDealAction = createAction('SEND_TICKET_PLEA_DEAL');
export const uploadTicketReceiptAction = createAction('UPLOAD_TICKET_RECEIPT');
