import * as React from 'react';
import { SVGProps } from 'react';

const EditProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#6E7783"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 9a3.75 3.75 0 1 0 0-7.5A3.75 3.75 0 0 0 9 9Z"
    />
    <path
      stroke="#6E7783"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m14.408 11.805-2.655 2.655a.924.924 0 0 0-.226.442l-.142 1.013c-.053.367.203.623.57.57l1.012-.142a.892.892 0 0 0 .443-.226l2.655-2.655c.457-.457.675-.99 0-1.665-.668-.667-1.2-.45-1.658.008Z"
    />
    <path
      stroke="#6E7783"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14.025 12.188c.225.81.855 1.44 1.665 1.664"
    />
    <path
      stroke="#6E7783"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.558 16.5c0-2.902 2.887-5.25 6.442-5.25.78 0 1.53.113 2.227.322"
    />
  </svg>
);
export default EditProfileIcon;
