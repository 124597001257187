import * as React from 'react';
import { SVGProps } from 'react';

function DocumentIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.87508 8.75004H6.12508C6.27855 8.75004 6.4072 8.69813 6.51102 8.59431C6.61484 8.49049 6.66675 8.36185 6.66675 8.20837C6.66675 8.0549 6.61484 7.92626 6.51102 7.82244C6.4072 7.71862 6.27855 7.66671 6.12508 7.66671H2.87508C2.72161 7.66671 2.59296 7.71862 2.48914 7.82244C2.38532 7.92626 2.33341 8.0549 2.33341 8.20837C2.33341 8.36185 2.38532 8.49049 2.48914 8.59431C2.59296 8.69813 2.72161 8.75004 2.87508 8.75004ZM2.87508 6.58337H6.12508C6.27855 6.58337 6.4072 6.53147 6.51102 6.42765C6.61484 6.32383 6.66675 6.19518 6.66675 6.04171C6.66675 5.88824 6.61484 5.75959 6.51102 5.65577C6.4072 5.55195 6.27855 5.50004 6.12508 5.50004H2.87508C2.72161 5.50004 2.59296 5.55195 2.48914 5.65577C2.38532 5.75959 2.33341 5.88824 2.33341 6.04171C2.33341 6.19518 2.38532 6.32383 2.48914 6.42765C2.59296 6.53147 2.72161 6.58337 2.87508 6.58337ZM1.25008 10.9167C0.952165 10.9167 0.69713 10.8106 0.484977 10.5985C0.272824 10.3863 0.166748 10.1313 0.166748 9.83337V1.16671C0.166748 0.868791 0.272824 0.613756 0.484977 0.401603C0.69713 0.18945 0.952165 0.083374 1.25008 0.083374H5.13654C5.28098 0.083374 5.41866 0.110457 5.54956 0.164624C5.68046 0.218791 5.79557 0.295527 5.89487 0.394832L8.52196 3.02192C8.62126 3.12122 8.698 3.23633 8.75216 3.36723C8.80633 3.49813 8.83341 3.6358 8.83341 3.78025V9.83337C8.83341 10.1313 8.72734 10.3863 8.51519 10.5985C8.30303 10.8106 8.048 10.9167 7.75008 10.9167H1.25008ZM5.04175 3.33337C5.04175 3.48685 5.09366 3.61549 5.19748 3.71931C5.3013 3.82313 5.42994 3.87504 5.58341 3.87504H7.75008L5.04175 1.16671V3.33337Z" fill="#FEFEFE" />
    </svg>
  );
}
export default DocumentIcon;
