import _ from 'lodash';

import axios from '@config/axios';

const convertToQueryParams = (queryParams) => {
  const paramsArray = Object.entries(queryParams).reduce((data, [ key, value ]) => {
    // if value is array
    if (Array.isArray(value)) {
      value.forEach((arrayValue, index) => {
        data.push(`${key}[${index}]=${arrayValue}`);
      });
    } else {
      // if not array just proceed as normal params
      data.push(`${key}=${value}`);
    }

    return data;
  }, []);

  return `?${paramsArray.join('&')}`;
};

// if not return the error
const handleError = (error) => Promise.reject(error);

export const getRequest = (route, options) => {
  let queryParams = '';
  if (options && options.queryParams) {
    queryParams = convertToQueryParams(options.queryParams);
    // eslint-disable-next-line no-param-reassign
    delete options.queryParams;
  }

  const url = `/${route}${queryParams}`;

  const promise = axios.get(url, {
    ...options,
    responseType: 'json',
    transitional: {
      silentJSONParsing: true,
    },
  })
    .then((response) => {
      if (_.isNull(response.data)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: 400,
          response: {},
        });
      }
      return response;
    })
    .catch(error => handleError(error));

  return {
    $promise: promise,
  };
};

export const postRequest = (route, params, options) => {
  const url = `/${route}`;
  const promise = axios.post(url, params, {
    ...options,
  }).then((resp) => resp).catch(error => handleError(error));
  return {
    $promise: promise,
  };
};

export const deleteRequest = (route, options) => {
  const url = `/${route}`;

  const promise = axios.delete(url, {
    ...options,
  })
    .catch(error => handleError(error));

  return {
    $promise: promise,
  };
};

export const putRequest = (route, params, options) => {
  const url = `/${route}`;

  const promise = axios.put(url, params, {
    ...options,
  }).catch(error => handleError(error));

  return {
    $promise: promise,
  };
};
