import * as React from 'react';

function ErrorIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        stroke="#E30000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10 18.333c4.583 0 8.333-3.75 8.333-8.333S14.583 1.667 10 1.667 1.667 5.417 1.667 10s3.75 8.333 8.333 8.333ZM10 6.667v4.166"
      />
      <path
        stroke="#E30000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9.996 13.333h.007"
      />
    </svg>
  );
}
export default ErrorIcon;
