import * as React from 'react';
import { SVGProps } from 'react';

const InfoIconBlue = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#3183DD"
      d="M7.753 12.158a.742.742 0 0 0 .546-.223.744.744 0 0 0 .222-.546.742.742 0 0 0-.223-.546.743.743 0 0 0-.546-.223.742.742 0 0 0-.546.223.743.743 0 0 0-.222.547c0 .215.074.397.223.545.148.149.33.223.546.223Zm.01 2.93a6.981 6.981 0 0 1-2.796-.564 7.24 7.24 0 0 1-2.276-1.531 7.236 7.236 0 0 1-1.532-2.275 6.975 6.975 0 0 1-.565-2.795c0-.991.189-1.923.565-2.795A7.24 7.24 0 0 1 2.69 2.852 7.236 7.236 0 0 1 4.965 1.32 6.975 6.975 0 0 1 7.76.755c.991 0 1.923.188 2.795.565a7.24 7.24 0 0 1 2.276 1.531 7.236 7.236 0 0 1 1.532 2.275c.377.872.565 1.803.565 2.795a6.98 6.98 0 0 1-.565 2.795 7.24 7.24 0 0 1-1.531 2.276 7.238 7.238 0 0 1-2.275 1.532 6.975 6.975 0 0 1-2.795.565Zm.06-10.395c.347 0 .649.11.903.328.254.22.381.493.381.822 0 .277-.08.524-.243.743a4.189 4.189 0 0 1-.557.61c-.274.243-.515.51-.724.802-.208.292-.32.616-.334.974a.427.427 0 0 0 .145.346.51.51 0 0 0 .356.139.512.512 0 0 0 .368-.145.661.661 0 0 0 .193-.356c.05-.259.157-.49.321-.692.164-.203.342-.396.533-.578.275-.268.515-.56.72-.877.205-.316.308-.67.308-1.059 0-.598-.236-1.089-.707-1.473a2.53 2.53 0 0 0-1.65-.577 2.8 2.8 0 0 0-1.269.298 2.177 2.177 0 0 0-.928.87.517.517 0 0 0-.066.37c.024.129.09.227.199.294a.582.582 0 0 0 .424.066.638.638 0 0 0 .37-.248c.153-.199.338-.358.555-.478.217-.12.45-.18.701-.18Z"
    />
  </svg>
);
export default InfoIconBlue;
