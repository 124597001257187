import { handleActions } from 'redux-actions';

// import ActionDispatched from '@services/action-dispatchers/ActionDispatcherContract';

import {
  getCountiesAction,
  getExpeditedServiceCostAction,
  getStatesAction,
} from './AppActionConfig';

interface CountiesState {
  id:string,
  state_code:string,
  name:string,
}
interface State {
  code:string,
  name:string,
}

interface AppState {
  counties: CountiesState[];
  states: State[];
  services: any;
}

const defaultState: AppState = {
  counties: [],
  states: [],
  services: [],
};

const reducer = handleActions(
  {
    [getCountiesAction.toString()]: (state: AppState, { payload }) => ({
      ...state,
      counties: [ ...payload.data ],
    }),
    [getStatesAction.toString()]: (state: AppState, { payload }) => ({
      ...state,
      states: [ ...payload.data ],
    }),
    [getExpeditedServiceCostAction]: (state, { payload }) => ({
      ...state,
      services: [ ...payload.data ],
    }),
  },
  defaultState,
);

export default reducer;
