import {
  generatePostAction, generateGetAction, generateAction, generatePutAction,
} from '@services/ActionDispatcher';

import {
  loginAction,
  logoutAction,
  clearUserTokenAction,
  getUserDetailsAction,
  validateRegisterInfoAction,
  validateRegisterBusinessAction,
  sendCodeAction,
  verifyCodeAction,
  registerAction,
  uploadSignatureAction,
  removeSignatureAction,
  changeForgotPasswordAction,

  setLawyerSignatureAction,
  editProfileAction,
  changePasswordAction,
  editBusinessInformationAction,
  validateEditProfileAction,
  editAttorneyFeeAction,
  editAllowExpeditedCaseAction,
} from './AuthActionConfig';

const deviceData = {
  device_uuid: localStorage.getItem('DEVICE_UUID'),
};

// action request dispatchers
const loginRequest = data => generatePostAction(
  // action name
  loginAction,
  // url
  'auth/login',
  {
    ...data,
    ...deviceData,
    // remove this if user is now allowed in login
    type: 'lawyer',
  },
);

const logoutRequest = () => generatePostAction(
  // action name
  logoutAction,
  // url
  'auth/logout',
  { queryParams: deviceData },
);

const getUserDetailsRequest = () => generateGetAction(
  // action name
  getUserDetailsAction,
  // url
  'auth/me',
  { queryParams: deviceData },
);

const clearUserTokenRequest = () => generateAction(
  // action name
  clearUserTokenAction,
);

const validateRegisterInfoRequest = (data) => generatePostAction(
  // action name
  validateRegisterInfoAction,
  // url
  'auth/lawyer/register/validate-info',
  data,
);

const validateRegisterBusinessRequest = (data) => generatePostAction(
  // action name
  validateRegisterBusinessAction,
  // url
  'auth/lawyer/register/validate-business',
  data,
);

const sendCodeRequest = (data: object) => generatePostAction(
  sendCodeAction,
  'auth/code/send',
  data,
);

const verifyCodeRequest = (data: object) => generatePostAction(
  verifyCodeAction,
  'auth/code/verify',
  data,
);
const changeForgotPasswordRequest = (data: object) => generatePostAction(
  changeForgotPasswordAction,
  'auth/forgot-password/change-password',
  data,
);
const registerRequest = (data: object) => generatePostAction(
  registerAction,
  'auth/lawyer/register',
  { ...data, ...deviceData },
);

const uploadSignatureRequest = (data) => generatePostAction(
  uploadSignatureAction,
  'lawyer/signature/upload',
  { ...data },
);

const removeSignatureRequest = (data) => generatePostAction(
  removeSignatureAction,
  'lawyer/signature/remove',
  { ...data },
);

const editProfileRequest = (data) => generatePutAction(
  editProfileAction,
  'lawyer/profile',
  { ...data },
);

const changePasswordRequest = (data) => generatePutAction(
  changePasswordAction,
  'lawyer/profile/change-password',
  { ...data },
);

const editBusinessInformationRequest = (data) => generatePutAction(
  editBusinessInformationAction,
  'lawyer/profile/edit-business',
  { ...data },
);

const validateEditProfileRequest = (data) => generatePostAction(
  validateEditProfileAction,
  'lawyer/profile/validate',
  { ...data },
);

const editAttorneyFeeRequest = (data) => generatePutAction(
  editAttorneyFeeAction,
  'lawyer/profile/attorney-fee',
  { ...data },
);

const editAllowExpeditedCaseRequest = (data) => generatePutAction(
  editAllowExpeditedCaseAction,
  'lawyer/profile/expedited-case',
  { ...data },
);

const setLawyerSignatureDispatch = (data) => generateAction(setLawyerSignatureAction, data);

// register actions here
export {
  // normal dispatch
  clearUserTokenRequest,
  setLawyerSignatureDispatch,
  // async (request) dispatch
  // eslint-disable-next-line import/prefer-default-export
  loginRequest,
  logoutRequest,
  changeForgotPasswordRequest,
  // Shop registration
  getUserDetailsRequest,
  validateRegisterInfoRequest,
  validateRegisterBusinessRequest,
  sendCodeRequest,
  verifyCodeRequest,
  registerRequest,
  uploadSignatureRequest,
  removeSignatureRequest,
  editProfileRequest,
  changePasswordRequest,
  editBusinessInformationRequest,
  validateEditProfileRequest,
  editAttorneyFeeRequest,
  editAllowExpeditedCaseRequest,
};
