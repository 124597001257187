import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Container } from 'reactstrap';

import Layout from './layout/Layout'; import TicketConversationPage from './ticket-conversation/TicketConversationPage';

const Home = React.lazy(() => import('./home/Home'));
const AcceptTicketPage = React.lazy(() => import('./tickets/for-approval/AcceptTicketPage'));
const DealCostFineTicket = React.lazy(() => import('./tickets/ongoing/deal-case-fine/DealCaseFineTicketPage'));
const ViewTicketPage = React.lazy(() => import('./tickets/view-ticket/ViewTicketPage'));

const TemplatesPage = React.lazy(() => import('./layout/components/topnav/Templates/TemplatesPage'));
const AccountRouter = React.lazy(() => import('./layout/components/topnav/Account/AccountRouter'));
const AttorneyFeesPage = React.lazy(() => import('./layout/components/topnav/AttorneyFees/AttorneyFeesPage'));

const ClientRouter: FC = () => (
  <Container>
    <Routes>

      {/* Routes with top nav */}
      <Route path="/:category?" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="ticket/:id" element={<ViewTicketPage />} />
        <Route path="ticket/:id/deal-cost-fine" element={<DealCostFineTicket />} />
        <Route path="ticket/:id/accept" element={<AcceptTicketPage />} />
        <Route path="templates" element={<TemplatesPage />} />
        <Route path="account/*" element={<AccountRouter />} />
        <Route path="attorney-fees" element={<AttorneyFeesPage />} />

      </Route>

      {/* Routes without top nav */}
      <Route path="ticket/:id/conversation" element={<TicketConversationPage />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </Container>
);

export default ClientRouter;
