import Pusher, { Channel } from 'pusher-js';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import MessagingService from '@services/MessagingService';

const useMessaging = (callback: (event: { data: any }) => void, params: any, deps: any) => {
  const accessToken = useSelector(({ Auth }) => Auth.accessToken);
  const messaging = useRef<Pusher>();
  const channel = useRef<Channel>();

  useEffect(() => {
    // real-time messaging
    if (!messaging.current) {
      messaging.current = MessagingService.getInstance(accessToken);
    }

    if (params.channel) {
      channel.current = messaging.current.subscribe(params.channel);

      if (params.event) {
        channel.current.bind(params.event, callback);
      }
    }

    return () => {
      if (params.channel) {
        messaging.current?.unsubscribe(params.channel);

        if (params.event) {
          channel.current?.unbind(params.event);
        }
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useMessaging;
