import * as React from 'react';
import { SVGProps } from 'react';

function VisibilityOffIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.8975 7.1025L7.1025 10.8975C6.615 10.41 6.315 9.7425 6.315 9C6.315 7.515 7.515 6.315 9 6.315C9.7425 6.315 10.41 6.615 10.8975 7.1025Z" stroke={props?.stroke || '#6E7783'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.365 4.32749C12.0525 3.33749 10.5525 2.79749 9 2.79749C6.3525 2.79749 3.885 4.35749 2.1675 7.05749C1.4925 8.11499 1.4925 9.89249 2.1675 10.95C2.76 11.88 3.45 12.6825 4.2 13.3275" stroke={props?.stroke || '#6E7783'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.315 14.6475C7.17 15.0075 8.0775 15.2025 9 15.2025C11.6475 15.2025 14.115 13.6425 15.8325 10.9425C16.5075 9.88501 16.5075 8.10751 15.8325 7.05001C15.585 6.66001 15.315 6.29251 15.0375 5.94751" stroke={props?.stroke || '#6E7783'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.6325 9.52499C11.4375 10.5825 10.575 11.445 9.51752 11.64" stroke={props?.stroke || '#6E7783'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.1025 10.8975L1.5 16.5" stroke={props?.stroke || '#6E7783'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.5 1.5L10.8975 7.1025" stroke={props?.stroke || '#6E7783'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
export default VisibilityOffIcon;
