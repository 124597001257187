import * as React from 'react';
import { SVGProps } from 'react';

const LocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#DEE8F5" rx={6.667} />
    <path
      fill="#1A365C"
      d="M29.578 16.056C28.411 10.922 23.933 8.61 20 8.61h-.01c-3.923 0-8.412 2.3-9.579 7.433-1.3 5.734 2.211 10.59 5.39 13.645a6.04 6.04 0 0 0 4.2 1.7 5.994 5.994 0 0 0 4.188-1.7c3.178-3.056 6.689-7.9 5.389-13.633ZM20 21.622a3.5 3.5 0 1 1 0-6.999 3.5 3.5 0 0 1 0 7Z"
    />
  </svg>
);
export default LocationIcon;
