import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import theme from '@config/theme';
import { DeleteIcon } from '@shared/icons';
import UserAvatar from '@shared/utils/UserAvatar/UserAvatar';

import ChatAttachment from '../chat-attachment/ChatAttachment';
import ChatImage from '../chat-image/ChatImage';
import ChatMessage from '../chat-message/ChatMessage';

import './ChatBubble.scss';

const ChatBubble = (props: {
  item: any;
  prevItem: any;
  nextItem: any;
  onRetry: () => void;
  onDelete: () => void;
  onViewImage: () => void;
  onViewDocument: () => void;
}) => {
  const userId = useSelector((state: any) => state.Auth.user.id);
  const {
    item, prevItem, nextItem, onRetry, onViewImage, onViewDocument, onDelete,
  } = props;
  const sent = item.sender_id === userId;
  const failed = item.status === 'error';
  const showDate = !nextItem || (nextItem && !moment.utc(item.created_at).local()
    .isSame(moment.utc(nextItem.created_at).local(), 'date'));
  const renderItem = () => (
    <>
      {item.message_type === 'message' && (
        <ChatMessage
          message={item.message}
          date={item.created_at}
          failed={failed}
          sent={sent}
          sending={item.status === 'sending'}
          onRetry={onRetry}
        />
      )}
      {item.message_type === 'image' && (
        <ChatImage
          image={item.attachment}
          date={item.created_at}
          failed={failed}
          sent={sent}
          onRetry={onRetry}
          sending={item.status === 'sending'}
          onViewImage={onViewImage}
        />
      )}
      {item.message_type === 'attachment' && (
        <ChatAttachment
          data={item.data}
          date={item.created_at}
          failed={failed}
          sent={sent}
          sending={item.status === 'sending'}
          onRetry={onRetry}
          onViewDocument={onViewDocument}
        />
      )}
    </>
  );

  return (
    <>
      {sent ? (
        <div className="chat-group d-flex justify-content-end align-items-center">
          {
            failed ? <DeleteIcon fill={theme.color.danger} className="cursor-pointer" onClick={onDelete} /> : null
          }
          {renderItem()}
        </div>
      ) : (
        <div className="chat-group d-flex">
          <div className="avatar-container">
            {(prevItem?.sender_id === userId || !prevItem) && (
              <UserAvatar
                image={item.sender.image}
                user={item.sender}
              />
            )}
          </div>
          {renderItem()}
        </div>
      )}
      {showDate && (
        <div className="date my-3">
          {moment.utc(item.created_at).local().format('MMMM DD')}
        </div>
      )}
    </>
  );
};
export default ChatBubble;
