import * as React from 'react';
import { SVGProps } from 'react';

const AddIconNoBg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    fill="none"
    {...props}
  >
    <path
      stroke="#8F9CB0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.5 1.857v12m6-6h-12"
    />
  </svg>
);
export default AddIconNoBg;
