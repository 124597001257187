import * as React from 'react';
import { SVGProps } from 'react';

function SentIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.04489 7.34747L1.28206 4.58466C1.18293 4.48552 1.134 4.3695 1.13528 4.23659C1.13656 4.10369 1.18891 3.98554 1.29233 3.88212C1.39574 3.78554 1.51283 3.73554 1.64359 3.73212C1.77436 3.7287 1.89146 3.7787 1.99488 3.88212L4.47693 6.36417L4.70773 6.13339L5.41026 6.83594L4.88846 7.34747C4.76795 7.46115 4.62736 7.5197 4.46668 7.52312C4.306 7.52653 4.16541 7.46799 4.04489 7.34747ZM7.81156 7.34747L5.04874 4.58466C4.95643 4.49236 4.90921 4.37805 4.90708 4.24172C4.90493 4.1054 4.95216 3.98554 5.04874 3.88212C5.15216 3.7787 5.27096 3.72699 5.40515 3.72699C5.53932 3.72699 5.65812 3.7787 5.76154 3.88212L8.23334 6.35392L14.0154 0.571857C14.1145 0.472713 14.2306 0.423785 14.3635 0.425074C14.4964 0.426351 14.6145 0.478702 14.7179 0.582124C14.8145 0.685535 14.8645 0.802624 14.8679 0.933391C14.8714 1.06416 14.8214 1.18125 14.7179 1.28466L8.65513 7.34747C8.53462 7.46799 8.39402 7.52824 8.23334 7.52824C8.07267 7.52824 7.93207 7.46799 7.81156 7.34747ZM8.00256 4.23339L7.28976 3.53084L10.2385 0.582124C10.3308 0.489813 10.4451 0.442591 10.5814 0.440458C10.7177 0.438313 10.8376 0.485535 10.941 0.582124C11.0444 0.685535 11.0962 0.804335 11.0962 0.938524C11.0962 1.0727 11.0444 1.1915 10.941 1.29492L8.00256 4.23339Z" fill="#FEFEFE" stroke="#FEFEFE" strokeWidth="0.3" />
    </svg>
  );
}
export default SentIcon;
