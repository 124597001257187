import {
  generateAction,
  generateGetAction,
  generatePostAction,
} from '@services/ActionDispatcher';

import {
  deleteMessageAction,
  displayMessageAction,
  getTicketMessagesAction,
  markTicketConversationAsReadAction,
  sendMessageAction,
  updateMessageAction,
} from './TicketConversationActionConfig';

const displayMessageDispatch = (data: any) => generateAction(displayMessageAction, data);
const updateMessageDispatch = (data: any) => generateAction(updateMessageAction, data);
const deleteMessageDispatch = (data: any) => generateAction(deleteMessageAction, data);

// action request dispatchers
const sendMessageRequest = (id: string, data: any) => generatePostAction(
  sendMessageAction,
  `lawyer/ticket/${id}/send-message`,
  data,
);

const getTicketMessagesRequest = (id: string, data: any) => generateGetAction(
  getTicketMessagesAction,
  `lawyer/ticket/${id}/conversations`,
  { queryParams: data },
  { id },
);

const markTicketConversationAsReadRequest = (id: string) => generatePostAction(
  markTicketConversationAsReadAction,
  `lawyer/ticket/${id}/conversations/mark-as-read`,
  {},
);

// register actions here
export {
  // normal dispatch
  displayMessageDispatch,
  updateMessageDispatch,
  deleteMessageDispatch,

  // async (request) dispatch
  sendMessageRequest,
  getTicketMessagesRequest,
  markTicketConversationAsReadRequest,
};
