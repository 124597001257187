import * as React from 'react';
import { SVGProps } from 'react';

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#6E7783"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.675 5.67c.232-2.7 1.62-3.803 4.657-3.803h.098c3.353 0 4.695 1.343 4.695 4.695v4.89c0 3.353-1.342 4.696-4.695 4.696h-.098c-3.015 0-4.402-1.088-4.65-3.743M11.25 9H2.715M4.388 6.487 1.875 9l2.513 2.512"
    />
  </svg>
);
export default LogoutIcon;
