import moment from 'moment';
import numeral from 'numeral';

import LocaleService from '@services/LocaleService';
// eslint-disable-next-line
import packageJson from '@root/package.json';
const packageName = packageJson.name;

const currencyFormat = (value, format = '0,0.00', currency = '₱', pad = true) => `${currency}${pad ? ' ' : ''}${numeral(value).format(format)}`;

const shortDateFormat = date => moment(date).format('L');

const serverDateFormat = date => moment(date).format('YYYY-MM-DD');

// Replace underscore with spaces and capitalize words
const humanize = (str) => {
  const removedUnderscore = str.split('_');
  const capitalizedWords = removedUnderscore.map(
    word => word.slice(0, 1).toUpperCase() + word.slice(1),
  );
  const joinedWords = capitalizedWords.join(' ');
  // Capitalize words that has slash symbol
  const removeSlashed = joinedWords.split('/');
  const mappedSlashed = removeSlashed.map(word => word.slice(0, 1).toUpperCase() + word.slice(1));
  // Capitalize words that has dash symbol
  const joinedMappedWords = mappedSlashed.join('/');
  const removeDashed = joinedMappedWords.split('-');
  const mappedDashed = removeDashed.map(word => word.slice(0, 1).toUpperCase() + word.slice(1));
  return mappedDashed.join('-');
};

const time24HourFormat = date => moment(date).format('HH:mm');

const dateFormat = date => moment(date).format('YYYY-MM-DD');

const timestampToMonthDay = date => moment(date).format('MMM. DD');

const timestampToDate = (date, showYear = false) => {
  const parsedDate = moment(date);
  if (showYear) return parsedDate.format('MMM. D, YYYY');

  const now = moment();
  return now.isSame(date, 'year') ? parsedDate.format('MMM. D') : parsedDate.format('MMM. D, YYYY');
};

const timestampToDayOfWeek = date => moment(date).format('dddd');

const timestampToDateTime = date => moment(date).format('MMMM D, YYYY h:mm a');

const normalizeMobile = (mobile) => {
  let normalizedMobile = mobile;
  if (mobile.startsWith('0')) {
    normalizedMobile = normalizedMobile.replace('0', '');
  } else if (mobile.startsWith('63')) {
    normalizedMobile = normalizedMobile.replace('63', '');
  } else if (mobile.startsWith('+63')) {
    normalizedMobile = normalizedMobile.replace('+63', '');
  }
  return normalizedMobile;
};

const getCurrentVersion = () => localStorage.getItem(`${packageName}-version`);

const isLatestVersion = currentVersion => currentVersion === getCurrentVersion();

const setLatestVersion = version => localStorage.setItem(`${packageName}-version`, version);

export const formatBytes = (bytes, decimals = 1) => {
  if (!+bytes) return '0 B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [ 'B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getFileExtension = filename => filename.split('.').pop();

export const localDate = (date) => moment.utc(date).local();

export const validate = async (schema, formData) => {
  const errObj = {};

  await schema.validate(formData, { abortEarly: false }).catch(err => {
    err.inner.forEach((e) => {
      // remove the quotation marks of the array index
      const initialPath = e.path.replace(/["']/g, '');
      // format the array of index validation with "validation.index"
      const finalPath = initialPath.replace(/\[([^\]]+)\]/g, '.$1');
      errObj[finalPath] = LocaleService.getTranslations(e.message);
    });
  });

  return errObj;
};

const hex = (c) => {
  const v = `0${c.charCodeAt(0).toString(16)}`;
  return `\\x${v.substr(v.length - 2)}`;
};

const stringEscape = s => (s ? s
  .replace(/\\/g, '\\\\')
  .replace(/\n/g, '\\n')
  .replace(/\t/g, '\\t')
  .replace(/\v/g, '\\v')
  .replace(/'/g, "\\'")
  .replace(/"/g, '\\"')
  // eslint-disable-next-line
  .replace(/[\x00-\x1F\x80-\x9F]/g, hex) : s);

const formatPhoneNumber = (number) => {
  const numericValue = number.replace(/\D/g, '');
  const formattedValue = numericValue.replace(/(\d{0,3})(\d{0,3})?(\d{0,4})?/, (match, p1, p2, p3) => {
    let formattedNumber = '';
    if (p1) formattedNumber += `(${p1}`;
    if (p2) formattedNumber += `) ${p2}`;
    if (p3) formattedNumber += ` ${p3}`;
    return formattedNumber;
  });

  return formattedValue;
};

export {
  currencyFormat,
  shortDateFormat,
  humanize,
  dateFormat,
  time24HourFormat,
  timestampToMonthDay,
  timestampToDate,
  timestampToDayOfWeek,
  timestampToDateTime,
  normalizeMobile,
  serverDateFormat,
  getCurrentVersion,
  isLatestVersion,
  setLatestVersion,
  stringEscape,
  formatPhoneNumber,
};
