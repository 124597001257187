/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const loginAction = createAction('PCS_SELLER_AUTH_LOGIN');
export const logoutAction = createAction('PCS_SELLER_AUTH_LOGOUT');
export const clearUserTokenAction = createAction('PCS_SELLER_USER_CLEAR_TOKEN');
export const getUserDetailsAction = createAction('PCS_AUTH_GET_USER_DETAILS');
export const validateRegisterInfoAction = createAction('PCS_AUTH_VALIDATE_REGISTER_INFO');
export const validateRegisterBusinessAction = createAction('PCS_AUTH_VALIDATE_REGISTER_BUSINESS');
export const sendCodeAction = createAction('APP_SEND_CODE');
export const verifyCodeAction = createAction('APP_VERIFY_CODE');
export const registerAction = createAction('APP_REGISTER');
export const uploadSignatureAction = createAction('UPLOAD_USER_SIGNATURE');
export const removeSignatureAction = createAction('REMOVE_USER_SIGNATURE');
export const changeForgotPasswordAction = createAction('APP_CHANGE_FORGOT_PASSWORD');
export const setLawyerSignatureAction = createAction('SET_LAWYER_SIGNATURE');
export const editProfileAction = createAction('EDIT_LAWYER_PROFILE');
export const changePasswordAction = createAction('CHANGE_LAWYER_PASSWORD');
export const editBusinessInformationAction = createAction('EDIT_BUSINESS_INFORMATION');
export const validateEditProfileAction = createAction('VALIDATE_EDIT_PROFILE');
export const editAttorneyFeeAction = createAction('EDIT_ATTORNEY_FEE');
export const editAllowExpeditedCaseAction = createAction('EDIT_ALLOW_EXPEDITED_CASE');
