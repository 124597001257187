import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import LocaleService from '@services/LocaleService';
import { CheckIcon, RefreshIcon, SentIcon } from '@shared/icons';

import './ChatImage.scss';

const ChatImage = ({
  image, date, failed, sent, sending, onRetry, onViewImage,
}: {
  image: string;
  sent: boolean;
  sending: boolean;
  failed: boolean;
  date: string;
  onRetry: () => void
  onViewImage: () => void
}) => {
  const i18n = LocaleService.getTranslations('chat');

  return (
    <div className={classNames('chat-image', {
      sent,
    })}
    >
      <div onClick={failed ? onRetry : onViewImage} className="cursor-pointer">
        <img
          src={image}
          alt="Attachment"
          className={classNames({ failed })}
        />
      </div>

      {failed ? (
        <div className="d-flex align-items-center retry">
          {i18n.button.retry}
          <RefreshIcon width={10} className="ms-1" />
        </div>
      ) : (
        <div>
          <div className="timestamp">
            {moment.utc(date).local().format('hh:mm A')}
            {sent && sending && (
              <CheckIcon className="icon-check" />
            )}
            {sent && !sending && (
              <SentIcon className="icon-sent" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatImage;
