import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import blueCar from '@assets/img/blue-car.gif';
import './style.scss';

const LoadingBlock = ({ enabled }) => {
  const [ showLoader, setShowLoader ] = useState(false);
  const [ dismountTimer, setDismountTimer ] = useState(null);

  useEffect(() => {
    const loader = document.getElementById('loader');
    if (loader) {
      loader.style.display = 'none';
    }
    if (enabled) {
      // Clear any existing dismount timer
      if (dismountTimer) {
        clearTimeout(dismountTimer);
        setDismountTimer(null);
      }
      // Show the loader immediately when enabled
      setShowLoader(true);
    } else {
      // When disabled, start a timer to hide the loader
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 300);
      setDismountTimer(timer);
    }

    return () => {
      if (dismountTimer) {
        clearTimeout(dismountTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ enabled ]);

  if (showLoader) {
    return (
      <div id="loader-app" className="loading-container">
        <img src={blueCar} alt="loader" width="50px" />
      </div>
    );
  }

  return null;
};

LoadingBlock.defaultProps = {
  enabled: false,
};

LoadingBlock.propTypes = {
  enabled: PropTypes.bool,
};

export default LoadingBlock;
