import * as React from 'react';
import { SVGProps } from 'react';

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#C1CAD7"
      d="M6 4.313a.567.567 0 0 1-.563-.563V1.5c0-.308.255-.563.563-.563.308 0 .563.255.563.563v2.25A.567.567 0 0 1 6 4.313ZM12 4.313a.567.567 0 0 1-.563-.563V1.5c0-.308.256-.563.563-.563.307 0 .563.255.563.563v2.25a.567.567 0 0 1-.563.563Z"
    />
    <path
      fill="#6E7783"
      d="M6.375 10.875a.747.747 0 0 1-.285-.06.774.774 0 0 1-.247-.157.775.775 0 0 1-.218-.533c0-.098.022-.195.06-.285a.866.866 0 0 1 .158-.247.774.774 0 0 1 .247-.158.766.766 0 0 1 .817.158.789.789 0 0 1 .218.532c0 .045-.008.098-.015.15a.477.477 0 0 1-.045.135.568.568 0 0 1-.068.135c-.022.037-.06.075-.09.113a.789.789 0 0 1-.532.217ZM9 10.875a.747.747 0 0 1-.285-.06.774.774 0 0 1-.248-.157.775.775 0 0 1-.217-.533c0-.098.023-.195.06-.285a.866.866 0 0 1 .157-.248.774.774 0 0 1 .248-.157.752.752 0 0 1 .818.157.789.789 0 0 1 .217.533c0 .045-.008.098-.015.15a.477.477 0 0 1-.045.135.568.568 0 0 1-.067.135c-.023.037-.06.075-.09.113a.789.789 0 0 1-.533.217ZM11.625 10.875a.747.747 0 0 1-.285-.06.774.774 0 0 1-.248-.157l-.09-.113a.568.568 0 0 1-.067-.135.477.477 0 0 1-.045-.135c-.008-.053-.015-.105-.015-.15 0-.195.082-.39.217-.533a.774.774 0 0 1 .248-.157.75.75 0 0 1 .818.157.789.789 0 0 1 .217.533c0 .045-.008.098-.015.15a.477.477 0 0 1-.045.135.568.568 0 0 1-.067.135c-.023.037-.06.075-.09.113a.789.789 0 0 1-.533.217ZM6.375 13.5a.747.747 0 0 1-.285-.06.866.866 0 0 1-.247-.157.789.789 0 0 1-.218-.533c0-.098.022-.195.06-.285a.7.7 0 0 1 .158-.248c.277-.277.787-.277 1.064 0a.789.789 0 0 1 .218.533c0 .195-.082.39-.218.533a.789.789 0 0 1-.532.217ZM9 13.5a.789.789 0 0 1-.533-.217.789.789 0 0 1-.217-.533c0-.098.023-.195.06-.285a.7.7 0 0 1 .157-.248c.278-.277.788-.277 1.066 0a.7.7 0 0 1 .157.248c.037.09.06.188.06.285 0 .195-.082.39-.217.533A.789.789 0 0 1 9 13.5ZM11.625 13.5a.789.789 0 0 1-.533-.217.7.7 0 0 1-.157-.248.747.747 0 0 1-.06-.285c0-.098.023-.195.06-.285a.7.7 0 0 1 .157-.248.749.749 0 0 1 .818-.157c.045.015.09.037.135.067.037.023.075.06.113.09a.789.789 0 0 1 .217.533c0 .195-.082.39-.217.533a.789.789 0 0 1-.533.217ZM15.375 7.38H2.625a.567.567 0 0 1-.563-.563c0-.307.256-.562.563-.562h12.75c.307 0 .563.255.563.562a.567.567 0 0 1-.563.563Z"
    />
    <path
      fill="#6E7783"
      d="M12 17.063H6c-2.737 0-4.313-1.575-4.313-4.313V6.375c0-2.737 1.576-4.313 4.313-4.313h6c2.738 0 4.313 1.576 4.313 4.313v6.375c0 2.738-1.575 4.313-4.313 4.313ZM6 3.188c-2.145 0-3.188 1.042-3.188 3.187v6.375c0 2.145 1.043 3.188 3.188 3.188h6c2.145 0 3.188-1.043 3.188-3.188V6.375c0-2.145-1.043-3.188-3.188-3.188H6Z"
    />
  </svg>
);
export default CalendarIcon;
