import * as React from 'react';
import { SVGProps } from 'react';

const UploadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 1 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.1425 1.5H5.8575C3.1275 1.5 1.5 3.1275 1.5 5.8575V12.135C1.5 14.8725 3.1275 16.5 5.8575 16.5H12.135C14.865 16.5 16.4925 14.8725 16.4925 12.1425V5.8575C16.5 3.1275 14.8725 1.5 12.1425 1.5ZM6.3525 6.735L8.6025 4.485C8.655 4.4325 8.715 4.395 8.7825 4.365C8.9175 4.305 9.075 4.305 9.21 4.365C9.2775 4.395 9.3375 4.4325 9.39 4.485L11.64 6.735C11.8575 6.9525 11.8575 7.3125 11.64 7.53C11.5275 7.6425 11.385 7.695 11.2425 7.695C11.1 7.695 10.9575 7.6425 10.845 7.53L9.555 6.24V10.8825C9.555 11.19 9.3 11.445 8.9925 11.445C8.685 11.445 8.43 11.19 8.43 10.8825V6.24L7.14 7.53C6.9225 7.7475 6.5625 7.7475 6.345 7.53C6.1275 7.3125 6.135 6.96 6.3525 6.735ZM13.68 12.915C12.1725 13.4175 10.59 13.6725 9 13.6725C7.41 13.6725 5.8275 13.4175 4.32 12.915C4.0275 12.8175 3.87 12.495 3.9675 12.2025C4.065 11.91 4.3875 11.745 4.68 11.85C7.47 12.78 10.5375 12.78 13.3275 11.85C13.62 11.7525 13.9425 11.91 14.04 12.2025C14.13 12.5025 13.9725 12.8175 13.68 12.915Z" fill="currentColor" />
  </svg>

);
export default UploadIcon;
