import { handleActions } from 'redux-actions';

import { logoutAction } from '@reducers/auth/AuthActionConfig';

import {
  deleteTemplateAction,
  getAllTemplatesAction,
  uploadTemplateAction,
} from './TemplatesActionConfig';

const defaultState = {
  uploadedTemplates: {},
};

const reducer = handleActions(
  {
    [getAllTemplatesAction.toString()]: (state, { payload }) => ({
      ...state,
      uploadedTemplates: payload,
    }),
    [uploadTemplateAction.toString()]: (state, { payload }) => ({
      ...state,
      uploadedTemplates: [ ...state.uploadedTemplates, payload ],
    }),
    [deleteTemplateAction.toString()]: (state, { params }) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { template_id } = params;

      // Filter out the template with the specified template_id
      const updatedTemplates = state.uploadedTemplates.filter(
        // eslint-disable-next-line camelcase
        template => template.id !== template_id);

      return ({
        ...state,
        uploadedTemplates: updatedTemplates,
      });
    },
    [logoutAction]() {
      return defaultState;
    },
  },
  defaultState,
);

export default reducer;
