const app = {
  // prevent redirection if 401 is received
  whitelistUrl: [
    '/login',
  ],
  appVersion: process.env.REACT_APP_VERSION || '1.0.0',
  apiUrl: `${process.env.REACT_APP_API_URL}`,
  pusher: {
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
  },
  env: process.env.REACT_APP_DEVELOPMENT_ENV,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  sentryPropagationTargets: process.env.REACT_APP_SENTRY_PROPAGATION_TARGETS || 'localhost',
};

export default app;
