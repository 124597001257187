import prettyBytes from 'pretty-bytes';
import React, { useImperativeHandle } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';

import { useAppProvider } from '@app/AppProvider';
import LocaleService from '@services/LocaleService';

interface IDropZone extends DropzoneOptions {
  onDrop?: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  accept?: any;
  capture?: boolean | 'user' | 'environment' | undefined
}

export type FileUploaderRef = {
  open: () => void;
};

function FileUploader({
  onDrop, accept, capture, ...rest
}: Omit<IDropZone, 'ref'>, ref: React.ForwardedRef<FileUploaderRef>) {
  const i18n = LocaleService.getTranslations('general');
  const { toast } = useAppProvider();
  const { getInputProps, open } = useDropzone({
    ...rest,
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            toast.error(LocaleService.parseTranslation(i18n.error.file_too_large, {
              fileSizeLimit: prettyBytes(rest.maxSize as number).replace(' ', ''),
            }), '');
          }

          if (err.code === 'file-invalid-type') {
            toast.error(i18n.error.file_type_invalid.title, i18n.error.file_type_invalid.message);
          }
        });
      });
      if (acceptedFiles.length === 0) return;
      onDrop?.(acceptedFiles, fileRejections);
    },
    multiple: false,
    accept,
  });

  const handleOpen = () => {
    open();
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  return (
    <input {...getInputProps()} capture={capture} />
  );
}

export default React.forwardRef<FileUploaderRef, IDropZone>(FileUploader);
