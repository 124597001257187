/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import React, {
  FormEvent, KeyboardEvent, useRef, useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import {
  Button, Container, Form, Input,
} from 'reactstrap';

import {
  CameraIcon, FileIcon, ImageIcon, SendIcon,
} from '@shared/icons';
import FileUploader, { FileUploaderRef } from '@shared/utils/FileUploader/FileUploader';
import WebcamCapture from '@shared/utils/WebcamCapture/WebcamCapture';

import './ChatInput.scss';

const ChatInput = ({ onSendMessage }: {
  onSendMessage: (item: { message?: any; messageType: 'message' | 'image' | 'attachment'; attachment?: any }) => void;
}) => {
  const [ message, setMessage ] = useState('');
  const [ showWebcam, setShowWebcam ] = useState(false);
  const imageUploader = useRef<FileUploaderRef>(null);
  const fileUploader = useRef<FileUploaderRef>(null);
  const mobileUploader = useRef<FileUploaderRef>(null);
  const form = useRef<HTMLFormElement>(null);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!message.trim()) return;
    setMessage('');
    onSendMessage({ message, messageType: 'message' });
  };

  const handleImageResult = async (result: File[]) => {
    const asset = result?.[0];
    onSendMessage({ attachment: asset, messageType: 'image' });
  };

  const handleFileResult = async (result: File[]) => {
    const asset = result?.[0];
    onSendMessage({ attachment: asset, messageType: 'attachment' });
  };

  const onKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (!e.shiftKey && e.keyCode === 13) {
      e.preventDefault();
      form.current?.requestSubmit();
    }
  };

  return (
    <div className="chat-input py-4 sticky-bottom">
      <Container fluid="md">
        <Form innerRef={form} onSubmit={handleSubmit} onKeyDown={onKeyDown} className="d-flex">
          <Button
            type="button"
            color="link"
            onClick={() => {
              if (!isMobile) {
                setShowWebcam(true);
                return;
              }
              mobileUploader.current?.open();
            }}
          >
            <CameraIcon width={30} height={30} className="icon" />
          </Button>
          <Button
            type="button"
            color="link"
            onClick={imageUploader.current?.open}
          >
            <ImageIcon width={30} height={30} className="icon" />
          </Button>
          <Button
            type="button"
            color="link"
            onClick={fileUploader.current?.open}
          >
            <FileIcon width={30} height={30} className="icon" />
          </Button>

          <Input
            type="textarea"
            placeholder="Type a message"
            className="border-0 shadow-none"
            onChange={({ target }) => setMessage(target.value)}
            value={message}
            rows={1}
          />

          <Button
            type="submit"
            color="link"
            disabled={!message.trim()}
            className="border-0"
          >
            <SendIcon
              width={30}
              height={30}
              className={classNames('icon', {
                'icon-send': !!message.trim(),
              })}
            />
          </Button>
        </Form>
      </Container>

      <FileUploader
        ref={mobileUploader}
        onDrop={(ac) => handleImageResult(ac)}
        accept={{
          'image/jpeg': [ '.jpeg', '.jpg', '.png' ],
        }}
        capture
      />

      <FileUploader
        ref={imageUploader}
        onDrop={(ac) => handleImageResult(ac)}
        accept={{
          'image/jpeg': [ '.jpeg', '.jpg', '.png' ],
        }}
      />

      <FileUploader
        ref={fileUploader}
        onDrop={(ac) => handleFileResult(ac)}
        accept={{
          'application/msword': [ '.docx', '.doc' ],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [ '.docx', '.doc' ],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [ '.xlsx', '.xls' ],
          'application/pdf': [ '.pdf' ],
          'application/x-pdf': [ '.pdf' ],
          'text/csv': [ '.csv' ],
        }}
      />

      <WebcamCapture
        show={showWebcam}
        toggle={() => setShowWebcam(false)}
        onSuccess={handleImageResult}
      />
    </div>
  );
};

export default ChatInput;
