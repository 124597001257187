import React, { lazy, Suspense } from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';

import { LoadingBlock } from '@shared/utils';

const Login = lazy(() => import('./login/LoginPage'));
const AuthLayout = lazy(() => import('./layout/AuthLayout'));
const CreateAccountPage = lazy(() => import('./create-account/CreateAccountPage'));

const AuthRouter = () => (
  <AuthLayout>
    <Suspense fallback={<LoadingBlock enabled />}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/create-account" element={<CreateAccountPage />} />
      </Routes>
    </Suspense>
  </AuthLayout>
);

export default AuthRouter;
