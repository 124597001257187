/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy } from 'react';
import {
  Route,
  useResolvedPath,
  Routes,
  Navigate,
} from 'react-router-dom';

import { LoadingBlock } from '@shared/utils';

const PageNotFound = lazy(() => import('./404/PageNotFound'));
// const InternalServer = lazy(() => import('./500/InternalServer'));

const ErrorRouter = () => {
  const url = useResolvedPath('').pathname;

  return (
    <Suspense fallback={<LoadingBlock enabled />}>
      <Routes>
        {/* <Route
          exact
          path={`${url}/500`}
          render={(props) => (<InternalServer {...props} />)}
        /> */}
        <Route exact path={`${url}/404`} render={props => (<PageNotFound {...props} />)} />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    </Suspense>
  );
};

export default ErrorRouter;
