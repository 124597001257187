import { generateGetAction } from '@services/ActionDispatcher';

import {
  getCountiesAction,
  getFederalAdmissionsAction,
  getPracticeAreasAction,
  getExpeditedServiceCostAction,
  getStatesAction,
} from './AppActionConfig';

const getCountiesRequest = () => generateGetAction(
  getCountiesAction,
  'user/counties?limit=200',
);

const getStatesRequest = (queryParams) => generateGetAction(
  getStatesAction,
  'states',
  {
    queryParams: {
      limit: 200,
      ...(queryParams || {}),
    },
  },
);

const getFederalAdmissionsRequest = (queryParams) => generateGetAction(
  getFederalAdmissionsAction,
  'federal-admissions',
  { queryParams },
);

const getPracticeAreasRequest = (queryParams) => generateGetAction(
  getPracticeAreasAction,
  'practice-areas',
  { queryParams },
);

const getExpeditedServiceCostRequest = (queryParams) => generateGetAction(
  getExpeditedServiceCostAction,
  'services',
  { queryParams },
);

export {
  getCountiesRequest,
  getStatesRequest,
  getFederalAdmissionsRequest,
  getPracticeAreasRequest,
  getExpeditedServiceCostRequest,
};
