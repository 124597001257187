import classnames from 'classnames';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,

} from 'reactstrap';

import placeholder from '@assets/img/blank-profile-picture.png';
import { logoutRequest } from '@reducers/auth/AuthAction';
import { clearUserTokenAction } from '@reducers/auth/AuthActionConfig';
import LocaleService from '@services/LocaleService';
import {
  HorizontalLogo, LogoutIcon,
  SignatureIcon,
  EditProfileIcon,
  BusinessInfoIcon,
  PasswordIcon,
  FeesIcon,
  TemplatesIcon,
} from '@shared/icons';

import './TopNavStyle.scss';

const TopNav: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch:any = useDispatch();
  const navigate = useNavigate();
  const i18n = LocaleService.getTranslations('general.topNav');
  const user = useSelector((state:any) => state.Auth.user);
  const handleLogout = async () => {
    try {
      await dispatch(logoutRequest());
    } catch (error) {
      dispatch(clearUserTokenAction());
    }
  };

  return (
    <nav className={classnames('navbar navbar-light bg-light gx-0 px-0 pt-4',
      { 'white-nav': pathname?.includes('ticket') && !pathname?.includes('accept') })}
    >
      <div className="container-fluid gx-0 mx-3 mx-md-5">
        <div className="d-flex gap-4 align-items-center">
          <span role="button" tabIndex={0} onClick={() => navigate('*')} aria-label="Home">
            <HorizontalLogo />
          </span>
        </div>
        <UncontrolledDropdown className="dropdown-menu-right">
          <DropdownToggle className="p-0 border-0" color="empty">
            <span>
              <img className="user-image" alt="Profile" src={user?.image || placeholder} />
            </span>
          </DropdownToggle>
          <DropdownMenu className="mt-3" right>
            <DropdownItem header className="text-uppercase">{i18n.header.title}</DropdownItem>
            <DropdownItem
              tag={NavLink}
              to="/account/profile"
              title={i18n.rightDropdown.personalInfo.title}
              className="d-flex gap-2 align-items-center dropdown-text b1"
            >
              <EditProfileIcon />{i18n.rightDropdown.personalInfo.name}
            </DropdownItem>
            <DropdownItem
              tag={NavLink}
              to="/account/business"
              title={i18n.rightDropdown.businessInfo.title}
              className="d-flex gap-2 align-items-center dropdown-text b1"
            >
              <BusinessInfoIcon />{i18n.rightDropdown.businessInfo.name}
            </DropdownItem>
            <DropdownItem
              tag={NavLink}
              to="/account/signature"
              title={i18n.rightDropdown.signature.title}
              className="d-flex gap-2 align-items-center dropdown-text b1"
            >
              <SignatureIcon />{i18n.rightDropdown.signature.name}
            </DropdownItem>
            <DropdownItem
              tag={NavLink}
              to="/account/change-password"
              title={i18n.rightDropdown.changePassword.title}
              className="d-flex gap-2 align-items-center dropdown-text b1"
            >
              <PasswordIcon />{i18n.rightDropdown.changePassword.name}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              tag={NavLink}
              to="/attorney-fees"
              title={i18n.rightDropdown.attorneyFee.title}
              className="d-flex gap-2 align-items-center dropdown-text b1"
            >
              <FeesIcon />{i18n.rightDropdown.attorneyFee.name}
            </DropdownItem>
            <DropdownItem
              tag={NavLink}
              to="/templates"
              title={i18n.rightDropdown.templates.title}
              className="d-flex gap-2 align-items-center dropdown-text b1"
            >
              <TemplatesIcon />{i18n.rightDropdown.templates.name}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              tag="a"
              href="#!"
              onClick={handleLogout}
              title={i18n.rightDropdown.logout.title}
              className="d-flex gap-2 align-items-center dropdown-text b1"
            >
              <LogoutIcon />{i18n.rightDropdown.logout.name}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </nav>
  );
};

export default TopNav;
