import * as React from 'react';
import { SVGProps } from 'react';

const AddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#3183DD"
      d="M12.143 1.5H5.857C3.127 1.5 1.5 3.127 1.5 5.857v6.278c0 2.738 1.627 4.365 4.357 4.365h6.278c2.73 0 4.357-1.627 4.357-4.357V5.857c.008-2.73-1.62-4.357-4.35-4.357ZM12 9.563H9.562V12a.567.567 0 0 1-.562.563.567.567 0 0 1-.563-.563V9.562H6A.567.567 0 0 1 5.437 9c0-.307.255-.563.563-.563h2.438V6c0-.308.255-.563.562-.563.307 0 .563.255.563.563v2.438H12c.307 0 .563.255.563.562a.567.567 0 0 1-.563.563Z"
    />
  </svg>
);
export default AddIcon;
