import chat from './chat.json';
import createAccount from './create-account.json';
import editProfile from './edit-profile.json';
import general from './general.json';
import home from './home.json';
import login from './login.json';
import signature from './signature.json';
import templates from './templates.json';
import tickets from './tickets.json';

export default {
  general,
  login,
  home,
  tickets,
  createAccount,
  signature,
  chat,
  templates,
  editProfile,
};
