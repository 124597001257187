import { handleActions } from 'redux-actions';

import {
  deleteMessageAction,
  displayMessageAction,
  getTicketMessagesAction,
  sendMessageAction,
  updateMessageAction,
} from './TicketConversationActionConfig';

const defaultState = {
  conversations: {},
  pagination: {},
};

const reducer = handleActions(
  {
    [displayMessageAction.toString()]: (state, { payload }) => {
      const conversations = state.conversations?.[payload.ticket_id] ?? [];

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.ticket_id]: [
            payload,
            ...conversations,
          ],
        },
      };
    },
    [updateMessageAction.toString()]: (state, { payload }) => {
      const conversations = state.conversations?.[payload.ticket_id] ?? [];
      const messageIndex = conversations.findIndex((c: any) => c.id === payload.id && !!c.status);

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.ticket_id]: [
            ...conversations.slice(0, messageIndex),
            { ...conversations[messageIndex], ...payload },
            ...conversations.slice(messageIndex + 1),
          ],
        },
      };
    },
    [deleteMessageAction.toString()]: (state, { payload }) => {
      const conversations = state.conversations?.[payload.ticket_id] ?? [];
      const updatedConversation = conversations.filter((c: any) => c.id !== payload.id);
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.ticket_id]: updatedConversation,
        },
      };
    },
    [sendMessageAction.toString()]: (state, { payload, params }) => {
      const conversations = state.conversations?.[payload.ticket_id] ?? [];
      const messageIndex = conversations.findIndex((c: any) => c.id === params.id && c.status === 'sending');

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.ticket_id]: [
            ...conversations.slice(0, messageIndex),
            payload,
            ...conversations.slice(messageIndex + 1),
          ],
        },
      };
    },
    [getTicketMessagesAction.toString()]: (state, { payload, params }) => {
      const conversations = state.conversations?.[params.id] ?? [];
      const {
        data,
        meta: { current_page: page, last_page: lastPage },
      } = payload;

      const messages = page === 1 ? data : [ ...conversations, ...data ];
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [params.id]: [
            ...messages,
          ],
        },
        pagination: {
          ...state.pagination,
          [params.id]: {
            current_page: page,
            last_page: lastPage,
          },
        },
      };
    },
  },
  defaultState,
);

export default reducer;
