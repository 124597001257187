import * as React from 'react';
import { SVGProps } from 'react';

const ChatIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#1A365C"
      d="m13.852 12.623.293 2.37a.746.746 0 0 1-1.125.734l-2.595-1.545c-.18-.104-.225-.33-.128-.51.375-.69.578-1.47.578-2.25 0-2.745-2.355-4.98-5.25-4.98-.593 0-1.17.09-1.71.27a.379.379 0 0 1-.48-.45C4.117 3.533 6.742 1.5 9.877 1.5c3.66 0 6.623 2.768 6.623 6.18 0 2.025-1.043 3.818-2.648 4.943Z"
    />
    <path
      fill="#1A365C"
      d="M9.75 11.422c0 .893-.33 1.718-.885 2.37-.742.9-1.92 1.478-3.24 1.478l-1.958 1.163c-.33.202-.75-.075-.704-.458l.187-1.477C2.145 13.8 1.5 12.681 1.5 11.422c0-1.32.705-2.483 1.785-3.173a4.262 4.262 0 0 1 2.34-.683c2.28 0 4.125 1.726 4.125 3.855Z"
    />
  </svg>
);
export default ChatIcon;
